import { gsap } from "gsap/all";
import { emailCode } from './utils.js';

console.log("footer.js triggered");  


export function footer() {
    console.log("footer.js triggered");  


        

        // Create the main container for the footer
        const siteFooterInner = document.createElement('div');
        siteFooterInner.id = 'site-footer-inner';
        siteFooterInner.className = 'site-footer-inner';
        // siteFooterInner.setAttribute('data-speed', '0.5');

        // Create the background div
        const footerBg = document.createElement('div');
        footerBg.className = 'footer-bg';
        siteFooterInner.appendChild(footerBg);
        //mainFooter.appendChild(footerBg);

        // Create the row-top div
        const rowTop = document.createElement('div');
        rowTop.className = 'row-top';
        const p1 = document.createElement('p');
        p1.className = 'mobile-hidden';
        const h1 = document.createElement('h1');
        const p2 = document.createElement('p');
        p1.textContent = "[ get in touch ]";
        p1.className = 'mobile-hidden';
        h1.textContent = "Let's Talk";
        p2.textContent = "WANT TO SEE MORE WORK? DISCUSS A NEW PROJECT? LET'S HAVE A CHAT.";
        rowTop.appendChild(p1);
        rowTop.appendChild(h1);
        rowTop.appendChild(p2);
        siteFooterInner.appendChild(rowTop);

        // Create the row-bottom div
        const rowBottom = document.createElement('div');
        rowBottom.className = 'row-bottom';

        // Create the col-1-5 divs
        for (let i = 0; i < 5; i++) {
            const col = document.createElement('div');
            col.className = 'col-1-5';
            
            // Create different content for each col-1-5
            switch (i) {
                case 0:
                    // // Create footer-logo
                    // const footerLogo = document.createElement('div');
                    // footerLogo.className = 'footer-logo';
                    // const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                    // svg.id = 'footer-logo';
                    // svg.setAttribute('data-name', 'Layer 1');
                    // svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
                    // svg.setAttribute('viewBox', '0 0 44.27 47.4');
                    // const path1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    // path1.setAttribute('class', 'cls-12');
                    // path1.setAttribute('d', 'M20.08,29.1l-3.68,3.29L29.96,0h-8.43c-2.59,0-3.29,2.24-3.29,2.24L0,47.4h13.35l6.73-18.29Z');
                    // const path2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    // path2.setAttribute('class', 'cls-12');
                    // path2.setAttribute('d', 'M24.19,18.29l3.68-3.29-13.56,32.4h8.43c2.59,0,3.29-2.24,3.29-2.24L44.27,0h-13.35s-6.73,18.29-6.73,18.29Z');
                    // svg.appendChild(path1);
                    // svg.appendChild(path2);
                    // footerLogo.appendChild(svg);
                    // col.appendChild(footerLogo);
                     // Create footer-logo
                     const footerLogo = document.createElement('div');
                     footerLogo.className = 'footer-logo';
                     const footerLogoIcon = document.createElement('div');
                     footerLogoIcon.className = 'footer-logo-icon';
                     const footerLogoType = document.createElement('div');
                     footerLogoType.className = 'footer-logo-type';
                     footerLogo.appendChild(footerLogoIcon);
                     footerLogo.appendChild(footerLogoType);
                     const svgIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
 
                     svgIcon.id = 'footer-logo';
                     svgIcon.setAttribute('data-name', 'Layer 1');
                     svgIcon.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
                     svgIcon.setAttribute('viewBox', '0 0 44.27 47.4');
                     const path1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path1.setAttribute('class', 'cls-12');
                     path1.setAttribute('d', 'M20.08,29.1l-3.68,3.29L29.96,0h-8.43c-2.59,0-3.29,2.24-3.29,2.24L0,47.4h13.35l6.73-18.29Z');
                     const path2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path2.setAttribute('class', 'cls-12');
                     path2.setAttribute('d', 'M24.19,18.29l3.68-3.29-13.56,32.4h8.43c2.59,0,3.29-2.24,3.29-2.24L44.27,0h-13.35s-6.73,18.29-6.73,18.29Z');
                     svgIcon.appendChild(path1);
                     svgIcon.appendChild(path2);
 
                     footerLogoIcon.appendChild(svgIcon);
                   
 
                     const svgType = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                     svgIcon.id = 'footer-logo2';
                     svgType.setAttribute('data-name', 'Layer 1');
                     svgType.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
                     svgType.setAttribute('viewBox', '0 0 390.84 48.28');
                     const path3 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path3.setAttribute('class', 'dt-logo-home');
                     path3.setAttribute('d', 'M390.84,3.22c0,1.83-1.44,3.26-3.3,3.26s-3.32-1.44-3.32-3.26,1.47-3.22,3.32-3.22,3.3,1.44,3.3,3.22ZM385.04,3.22c0,1.44,1.06,2.58,2.52,2.58s2.46-1.14,2.46-2.56-1.04-2.59-2.48-2.59-2.5,1.16-2.5,2.58ZM387.02,4.91h-.75V1.69c.29-.06.71-.1,1.24-.1.61,0,.88.1,1.12.24.18.14.31.39.31.71,0,.35-.28.63-.67.75v.04c.31.12.49.35.59.79.1.49.16.69.24.81h-.81c-.1-.12-.16-.41-.26-.79-.06-.35-.26-.51-.67-.51h-.35v1.3ZM387.04,3.09h.35c.41,0,.75-.14.75-.47,0-.29-.22-.49-.69-.49-.2,0-.33.02-.41.04v.92Z');
                     const path4 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path4.setAttribute('class', 'dt-logo-home');
                     path4.setAttribute('d', 'M65.2.03c15.49,0,27.72,5.62,27.72,23.67s-12.23,23.73-27.72,23.73h-15.63V.03h15.63ZM65.39,9.44h-4.64v28.57h4.64c8.24,0,15.69-1.76,15.69-14.32s-7.45-14.25-15.69-14.25Z');
                     const path5 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path5.setAttribute('class', 'dt-logo-home');
                     path5.setAttribute('d', 'M118.6,42.52c-2.16,3.46-4.9,5.75-10.33,5.75-9.35,0-12.16-5.3-12.16-14.84V12.25h10.79v18.5c0,5.03.13,9.15,5.36,9.15,4.58,0,6.34-2.61,6.34-9.09V12.25h10.79v35.17h-10.79v-4.9Z');
                     const path6 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path6.setAttribute('class', 'dt-logo-home');
                     path6.setAttribute('d', 'M154.75,24.09c0-4.12-2.09-5.62-5.95-5.62-3.46,0-5.88,1.77-6.28,5.16h-10.07c.46-6.6,6.86-12.49,17.26-12.49s15.89,4.05,15.69,14.78l-.13,7.65c-.07,4.58.13,10.85.78,13.86h-10.59l-.13-4.25c-2.61,3.73-6.8,5.1-11.83,5.1-6.67,0-12.16-3.92-12.16-10.33,0-8.63,9.74-11.51,23.4-12.49v-1.37ZM142.46,37.16c0,2.29,2.16,3.79,4.97,3.79,4.31,0,7.58-1.83,7.58-8.3v-.85c-7.52.65-12.55,1.63-12.55,5.36Z');
                     const path7 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path7.setAttribute('class', 'dt-logo-home');
                     path7.setAttribute('d', 'M179.13,47.43h-10.79V.03h10.79v47.4Z');
                     const path8 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path8.setAttribute('class', 'dt-logo-home');
                     path8.setAttribute('d', 'M202.08,9.84h-15.17V.03h41.65v9.81h-15.23v37.59h-11.24V9.84Z');
                     const path9 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path9.setAttribute('class', 'dt-logo-home');
                     path9.setAttribute('d', 'M253.13,11.47c9.02,0,11.83,5.3,11.83,14.84v21.12h-10.79v-18.44c0-5.03-.13-9.15-5.36-9.15-4.58,0-6.6,3.07-6.6,9.54v18.04h-10.79V.03h10.79v16.87c1.57-2.62,5.43-5.43,10.92-5.43Z');
                     const path10 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path10.setAttribute('class', 'dt-logo-home');
                     path10.setAttribute('d', 'M289.74,22.58c-1.18-.13-1.96-.2-2.94-.2-5.16,0-7.91,1.9-7.91,7.39v17.65h-10.79V12.25h10.39v6.67c1.63-4.12,4.58-6.73,9.61-6.93.52,0,1.11,0,1.64.07v10.53Z');
                     const path11 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path11.setAttribute('class', 'dt-logo-home');
                     path11.setAttribute('d', 'M326.41,36.38c-3.14,8.3-10.46,11.9-17.52,11.9-11.44,0-19.29-7-19.29-18.63s8.56-18.44,18.83-18.44c11.64,0,18.83,8.89,18.11,21.38h-26.09c.33,4.97,4.18,7.32,8.11,7.32s6.67-1.44,7.91-3.53h9.94ZM300.59,26.05h15.23c-.13-4.38-3.33-6.6-7.39-6.6-3.79,0-7.26,1.96-7.85,6.6Z');
                     const path12 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path12.setAttribute('class', 'dt-logo-home');
                     path12.setAttribute('d', 'M351.12,24.09c0-4.12-2.09-5.62-5.95-5.62-3.46,0-5.88,1.77-6.28,5.16h-10.07c.46-6.6,6.86-12.49,17.26-12.49s15.89,4.05,15.69,14.78l-.13,7.65c-.07,4.58.13,10.85.78,13.86h-10.59l-.13-4.25c-2.61,3.73-6.8,5.1-11.83,5.1-6.67,0-12.16-3.92-12.16-10.33,0-8.63,9.74-11.51,23.4-12.49v-1.37ZM338.83,37.16c0,2.29,2.16,3.79,4.97,3.79,4.31,0,7.58-1.83,7.58-8.3v-.85c-7.52.65-12.55,1.63-12.55,5.36Z');
                     const path13 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path13.setAttribute('class', 'dt-logo-home');
                     path13.setAttribute('d', 'M367.72,2.64h10.79v9.61h7.71v7.65h-7.71v15.76c0,3.33,1.05,3.99,4.64,3.99h3.07v7.78h-6.86c-8.63,0-11.64-1.63-11.64-9.87v-17.65h-5.56v-7.65h5.56V2.64Z');
                     const path14 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path14.setAttribute('class', 'dt-logo-home');
                     path14.setAttribute('d', 'M20.08,29.11l-3.68,3.29L29.96,0h-8.43c-2.59,0-3.29,2.24-3.29,2.24L0,47.4h13.35l6.73-18.29Z');
                     const path15 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                     path15.setAttribute('class', 'dt-logo-home');
                     path15.setAttribute('d', 'M24.19,18.3l3.68-3.29-13.56,32.4h8.43c2.59,0,3.29-2.24,3.29-2.24L44.27,0h-13.35s-6.73,18.29-6.73,18.29Z');
 
                     svgType.appendChild(path3);
                     svgType.appendChild(path4);
                     svgType.appendChild(path5);
                     svgType.appendChild(path6);
                     svgType.appendChild(path7);
                     svgType.appendChild(path8);
                     svgType.appendChild(path9);
                     svgType.appendChild(path10);
                     svgType.appendChild(path11);
                     svgType.appendChild(path12);
                     svgType.appendChild(path13);
                     svgType.appendChild(path14);
                     svgType.appendChild(path15);
 
                     footerLogoType.appendChild(svgType);
               
                     col.appendChild(footerLogo);
                    break;
                case 1:
                    // Create footer-menu
                    col.innerHTML = `
                        <p>NAVIGATION</p>
                        <div class='footer-menu'>
                            <div id='workBtnFooter'><p>Work</p></div>
                            <a href='/studio'><p class='unselectable'>Studio</p></a>
                            <a href='/contact'><p class='unselectable'>Contact</p></a>
                        </div>`;
                    break;
                case 2:
                    // Create address
                    col.innerHTML = `
                        <p>ADDRESS</p>
                        <p>1970 Mayview Drive</p>
                        <p>Los Angeles, CA 90027</p>
                        <p>USA</p>`;
                    break;
                case 3:
                    // Create footer-address
                    col.innerHTML = `
                        <p>GET IN TOUCH</p>
                        <div class='footer-address'>
                           <span class='email-container'></span>
                           <span class='phone-container'></span>
                        </div>`;
                    break;
                case 4:
                    // Create footer-socials
                    col.innerHTML = `
                    <div class='footer-socials'>
                    <a id="footer-linkedin-btn" href='https://www.linkedin.com/company/dual-threat-studio/'>
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
                            <path d="M127.8,0C93,0.1,51.5-0.1,44.4-0.1C10.9-0.1-0.5,6.8-0.2,55.4c0,13.7,0,37.8,0,72.6v128h128c34.8,0,52.6,0,66.3,0
                            c52.5,0,61.7-8.6,61.7-58.9V-0.1C127.8,0,162.6-0.1,127.8,0z M249.6,193.8c0,47.8-8.7,56-58.7,56c-13,0-30,0-63.1,0H6V128
                            c0-33.1,0-56.1,0-69.1C5.7,12.7,16.5,6.1,48.4,6.1c6.8,0,63.2,0.1,79.4,0.1c33.1-0.1,0,0,121.8-0.1V193.8z"/>
                            <rect x="43.5" y="93.9" width="37.2" height="119.7"/>
                            <path d="M62.1,34.4c-11.9,0-21.6,9.7-21.6,21.6s9.6,21.6,21.6,21.6S83.7,67.9,83.7,56S74,34.4,62.1,34.4z"/>
                            <path d="M175.4,90.9c-18.1,0-30.2,9.9-35.2,19.3h-0.5V93.8H104v119.7h37.2v-59.2c0-15.6,3-30.7,22.3-30.7s19.3,17.9,19.3,31.7v58.2
                              H220v-65.6C220,115.7,213,90.9,175.4,90.9z"/>

                      </svg>
                    </a>
                </div>
                <div class='footer-socials'>
                    <a id="footer-insta-btn" href='https://www.instagram.com/dualthreat.studio/' target='_blank'>
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
                         <path d="M127.6,0c-17,0-76.3-0.1-83.4-0.1C10.6-0.2-0.7,6.7-0.4,55.4c0.1,13.7,0,37.8,0,72.6c0,21.3,0,0,0,128c128,0,93.2,0,128,0
                           c34.8,0,52.6,0.1,66.3,0.1c52.5,0,61.7-8.6,61.7-58.9c0-13.7,0-34.4,0-69.2c0-34.8,0,0,0-128.1C127.6,0,162.3-0.1,127.6,0z
                            M249.4,128c0,33.1,0,52.8,0,65.8c0,47.8-8.8,56-58.7,56c-13,0-30-0.1-63.1-0.1c-33.1,0,0,0-121.8,0c0-121.8,0-101.5,0-121.8
                           c0-33.1,0.1-56.1,0-69.1C5.5,12.6,16.3,6,48.2,6c6.8,0,63.2,0.2,79.4,0.1c33.1-0.1,0,0,121.8-0.1C249.4,128,249.4,94.9,249.4,128z"
                           />
                         <path d="M202.8,64.8c9.4,0,17.1-7.7,17.1-17.1c0-9.4-7.6-17.1-17.1-17.1c-9.4,0-17.1,7.7-17.1,17.1
                           C185.7,57.1,193.3,64.8,202.8,64.8z"/>
                         <path d="M127.1,55.8C86.7,55.8,54,88.6,54,129c0,40.4,32.7,73.2,73.1,73.2c40.4,0,73.1-32.7,73.1-73.2
                           C200.3,88.6,167.5,55.8,127.1,55.8z M127.1,176.7c-26.3,0-47.7-21.3-47.7-47.7c0-26.3,21.4-47.7,47.7-47.7
                           c26.3,0,47.7,21.4,47.7,47.7C174.8,155.3,153.5,176.7,127.1,176.7z"/>
                      </svg>
                    </a>
                </div>`;
                    break;
                default:
                    break;
            }

            rowBottom.appendChild(col);
        }

        siteFooterInner.appendChild(rowBottom);

        // Create the copyright div
        // const copyright = document.createElement('div');
        // copyright.className = 'copyright';
        // copyright.innerHTML = '<h6>©2024 dualthreatinc.com. All rights reserved.</h6>';
        // siteFooterInner.appendChild(copyright);

        // // Create the legals div
        // const legals = document.createElement('div');
        // legals.className = 'legals';
        // legals.innerHTML = `
        //     <a href='/legal-notice'><h6>legal notice</h6></a>
        //     <a href='/legal-notice#data-protection' data-scroll-target='data-protection'><h6>data protection</h6></a>`;
        // siteFooterInner.appendChild(legals);

        // Append the entire footer to the main-footer element
        const mainFooter = document.getElementById('main-footer');
        const mainFooterMobile = document.getElementById('main-footer-mobile');
        mainFooter.innerHTML = ''; // Clear any existing content
        

        // if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {

        // console.log("FOOTER IPHONE");
        // mainFooterMobile.appendChild(siteFooterInner);

        //  } else {
        //     console.log("FOOTER DESKTOP");
        //     mainFooter.appendChild(siteFooterInner);
        // }

        if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            //console.log("This is an iOS device.");
            //mainFooterMobile.appendChild(siteFooterInner);
        } else {
            //console.log("This is not an iOS device!");
            //mainFooter.appendChild(siteFooterInner);
        }

        if (navigator.platform === 'iPad' || navigator.platform === 'iPhone' || navigator.platform === 'iPod') {
            console.log("4Footer - This is an iOS device.");
            mainFooterMobile.appendChild(siteFooterInner);
        } else {
            //console.log("This is not an iOS device!");
            mainFooter.appendChild(siteFooterInner);
        }


        emailCode();
    
  } 


  const nextCaseFunc = async function() {
    async function fetchNextCaseData() {
        //const response = await import(`../languages/${lang}.json`);
        const response = await fetch(`../json/projects.json`);
        console.log("JSON NEXTCASE FETCH succeeded");
        return response.json();
    }

    async function loadNextData() {
        const nextData = await fetchNextCaseData();
        // Process the nextData here as needed
        return nextData; // Return the data if necessary
    }

    return loadNextData(); // Return the promise from loadNextData
};


    function checkImageOrientation(imageUrl) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function() {
                // const orientation = img.width > img.height ? 'landscape' : 'portrait';
                const orientation = img.width > img.height ? 'landscape' : img.width < img.height ? 'portrait' : 'square';
                resolve(orientation);
            };
            img.onerror = function() {
                reject(new Error('Failed to load image'));
            };
            img.src = imageUrl;
        });
    }














//      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //
//      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //
//      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //
//      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //
//      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //
//      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //         //      FOOOOTER   CASE      //










    export function footerCases() {

     
    
        // async function applyImageOrientationClass(imageUrl, parentElement) {
        //     try {
        //         const orientation = await checkImageOrientation(imageUrl);
        //         parentElement.classList.add(orientation);
        //     } catch (error) {
        //         console.error('Error determining image orientation:', error);
        //     }
        // }    
        

    nextCaseFunc().then(nextData => {

        // Process nextData and update the footer accordingly
        //console.log(nextData);
    
        // Your existing code for updating the footer based on nextData goes here
        console.log("footerCASES.js triggered");  

        const projectNumber = document.getElementById('main-page').dataset.project;
        const imageURL1 = nextData[`proj_${projectNumber}_next1`];
        const imageURL2 = nextData[`proj_${projectNumber}_next2`];
        const imageURLlink = nextData[`proj_${projectNumber}_next_link`];
        const imageTitle = nextData[`proj_${projectNumber}_next_title`];
        const footerBgClr = nextData[`proj_${projectNumber}_footerBgClr`];

       

         // CHECK IMAGE ORIENTATION TO APPLY CLASS FOR ASPECT RATIO
        async function checkAndApplyImageOrientation(imageUrl, parentElement) {
        try {
            const orientation = await checkImageOrientation(imageUrl);
            parentElement.classList.add(orientation);
        } catch (error) {
            console.error('Failed to check image orientation:', error);
        }
    }

       
       

        

        // Create the main container for the footer
        const siteFooterInner = document.createElement('div');
        siteFooterInner.id = 'site-footer-inner';
        siteFooterInner.className = 'site-footer-inner';
        // siteFooterInner.setAttribute("data-speed", "0.25");

        // Create the background div
        const footerBg = document.createElement('div');
        footerBg.className = 'footer-bg';
        footerBg.setAttribute("data-bgclr", footerBgClr);
       
       
        // console.log(bgClr);
        // footerBg.style.backgroundColor = "bgClr";
        // siteFooterInner.appendChild(footerBg);
        siteFooterInner.appendChild(footerBg);
        

    // Create the div for the images container
        const footerNextImagesContainer = document.createElement('div');
        footerNextImagesContainer.id = 'footerNextImages';

        // Create a wrapper for the image wrappers with the class "next-item"
        const nextItemLeftWrapper = document.createElement('div');
        nextItemLeftWrapper.className = 'nextItem nextItem-left'; // You can set the desired class name here
        nextItemLeftWrapper.setAttribute("data-nextspeed", "50");

        const nextItemRightWrapper = document.createElement('div');
        nextItemRightWrapper.className = 'nextItem nextItem-right'; // You can set the desired class name here
        nextItemRightWrapper.setAttribute("data-nextspeed", "10");

        // Create the first image wrapper and wrap it in an anchor tag
        const footerImage1Wrapper = document.createElement('a');
        footerImage1Wrapper.id = 'footerImage1';
        footerImage1Wrapper.href = imageURLlink; // Add your URL here
    
        const footerImage1 = document.createElement('img');
        footerImage1.id = 'footerImage1Img'; // Add an ID for the image if needed
        footerImage1.className = 'footerImage';
        footerImage1.src = imageURL1;
        footerImage1.alt = 'Image 1';
        footerImage1Wrapper.appendChild(footerImage1);
        checkAndApplyImageOrientation(imageURL1, nextItemLeftWrapper);

        // Create the second image wrapper and wrap it in an anchor tag
        const footerImage2Wrapper = document.createElement('a');
        footerImage2Wrapper.id = 'footerImage2';
        footerImage2Wrapper.href = imageURLlink; // Add your URL here
        
        const footerImage2 = document.createElement('img');
        footerImage2.id = 'footerImage2Img'; // Add an ID for the image if needed
        footerImage2.className = 'footerImage';
        footerImage2.src = imageURL2;
        footerImage2.alt = 'Image 2';
        footerImage2Wrapper.appendChild(footerImage2);
        checkAndApplyImageOrientation(imageURL2, nextItemRightWrapper);

        // Append the image wrappers to the nextItemWrapper
        nextItemLeftWrapper.appendChild(footerImage1Wrapper);
        nextItemRightWrapper.appendChild(footerImage2Wrapper);


        // Append the images container to the footer
        siteFooterInner.appendChild(footerNextImagesContainer);
        footerNextImagesContainer.appendChild(nextItemLeftWrapper);
        footerNextImagesContainer.appendChild(nextItemRightWrapper);

        //create next project script
        const nextProj = document.createElement('a');
        nextProj.id = 'nextProjLink';
        nextProj.href = imageURLlink; // Add your URL here
        nextProj.className = 'next-proj';
        const nextProj_p = document.createElement('p');
        nextProj_p.textContent = "NEXT PROJECT";
        nextProj.appendChild(nextProj_p);
        const nextProj_h4 = document.createElement('h4');
        nextProj_h4.textContent = imageTitle;
        nextProj.appendChild(nextProj_h4);
        siteFooterInner.appendChild(nextProj);


        // Create the row-top div
        const rowTop = document.createElement('div');
        rowTop.className = 'row-top';
        const h1 = document.createElement('h1');
        h1.id = 'nextProj-title';
        h1.textContent = imageTitle;
        rowTop.appendChild(h1);
        siteFooterInner.appendChild(rowTop);

        // Create the row-bottom div
        const rowBottom = document.createElement('div');
        rowBottom.className = 'row-bottom';

        // Create the col-1-5 divs
        for (let i = 0; i < 5; i++) {
            const col = document.createElement('div');
            col.className = 'col-1-5';
            
            // Create different content for each col-1-5
            switch (i) {
                case 0:
                    // Create footer-logo
                    const footerLogo = document.createElement('div');
                    footerLogo.className = 'footer-logo';
                    const footerLogoIcon = document.createElement('div');
                    footerLogoIcon.className = 'footer-logo-icon';
                    const footerLogoType = document.createElement('div');
                    footerLogoType.className = 'footer-logo-type';
                    footerLogo.appendChild(footerLogoIcon);
                    footerLogo.appendChild(footerLogoType);
                    const svgIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

                    svgIcon.id = 'footer-logo';
                    svgIcon.setAttribute('data-name', 'Layer 1');
                    svgIcon.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
                    svgIcon.setAttribute('viewBox', '0 0 44.27 47.4');
                    const path1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path1.setAttribute('class', 'cls-12');
                    path1.setAttribute('d', 'M20.08,29.1l-3.68,3.29L29.96,0h-8.43c-2.59,0-3.29,2.24-3.29,2.24L0,47.4h13.35l6.73-18.29Z');
                    const path2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path2.setAttribute('class', 'cls-12');
                    path2.setAttribute('d', 'M24.19,18.29l3.68-3.29-13.56,32.4h8.43c2.59,0,3.29-2.24,3.29-2.24L44.27,0h-13.35s-6.73,18.29-6.73,18.29Z');
                    svgIcon.appendChild(path1);
                    svgIcon.appendChild(path2);

                    footerLogoIcon.appendChild(svgIcon);
                  

                    const svgType = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                    svgIcon.id = 'footer-logo2';
                    svgType.setAttribute('data-name', 'Layer 1');
                    svgType.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
                    svgType.setAttribute('viewBox', '0 0 390.84 48.28');
                    const path3 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path3.setAttribute('class', 'dt-logo-home');
                    path3.setAttribute('d', 'M390.84,3.22c0,1.83-1.44,3.26-3.3,3.26s-3.32-1.44-3.32-3.26,1.47-3.22,3.32-3.22,3.3,1.44,3.3,3.22ZM385.04,3.22c0,1.44,1.06,2.58,2.52,2.58s2.46-1.14,2.46-2.56-1.04-2.59-2.48-2.59-2.5,1.16-2.5,2.58ZM387.02,4.91h-.75V1.69c.29-.06.71-.1,1.24-.1.61,0,.88.1,1.12.24.18.14.31.39.31.71,0,.35-.28.63-.67.75v.04c.31.12.49.35.59.79.1.49.16.69.24.81h-.81c-.1-.12-.16-.41-.26-.79-.06-.35-.26-.51-.67-.51h-.35v1.3ZM387.04,3.09h.35c.41,0,.75-.14.75-.47,0-.29-.22-.49-.69-.49-.2,0-.33.02-.41.04v.92Z');
                    const path4 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path4.setAttribute('class', 'dt-logo-home');
                    path4.setAttribute('d', 'M65.2.03c15.49,0,27.72,5.62,27.72,23.67s-12.23,23.73-27.72,23.73h-15.63V.03h15.63ZM65.39,9.44h-4.64v28.57h4.64c8.24,0,15.69-1.76,15.69-14.32s-7.45-14.25-15.69-14.25Z');
                    const path5 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path5.setAttribute('class', 'dt-logo-home');
                    path5.setAttribute('d', 'M118.6,42.52c-2.16,3.46-4.9,5.75-10.33,5.75-9.35,0-12.16-5.3-12.16-14.84V12.25h10.79v18.5c0,5.03.13,9.15,5.36,9.15,4.58,0,6.34-2.61,6.34-9.09V12.25h10.79v35.17h-10.79v-4.9Z');
                    const path6 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path6.setAttribute('class', 'dt-logo-home');
                    path6.setAttribute('d', 'M154.75,24.09c0-4.12-2.09-5.62-5.95-5.62-3.46,0-5.88,1.77-6.28,5.16h-10.07c.46-6.6,6.86-12.49,17.26-12.49s15.89,4.05,15.69,14.78l-.13,7.65c-.07,4.58.13,10.85.78,13.86h-10.59l-.13-4.25c-2.61,3.73-6.8,5.1-11.83,5.1-6.67,0-12.16-3.92-12.16-10.33,0-8.63,9.74-11.51,23.4-12.49v-1.37ZM142.46,37.16c0,2.29,2.16,3.79,4.97,3.79,4.31,0,7.58-1.83,7.58-8.3v-.85c-7.52.65-12.55,1.63-12.55,5.36Z');
                    const path7 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path7.setAttribute('class', 'dt-logo-home');
                    path7.setAttribute('d', 'M179.13,47.43h-10.79V.03h10.79v47.4Z');
                    const path8 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path8.setAttribute('class', 'dt-logo-home');
                    path8.setAttribute('d', 'M202.08,9.84h-15.17V.03h41.65v9.81h-15.23v37.59h-11.24V9.84Z');
                    const path9 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path9.setAttribute('class', 'dt-logo-home');
                    path9.setAttribute('d', 'M253.13,11.47c9.02,0,11.83,5.3,11.83,14.84v21.12h-10.79v-18.44c0-5.03-.13-9.15-5.36-9.15-4.58,0-6.6,3.07-6.6,9.54v18.04h-10.79V.03h10.79v16.87c1.57-2.62,5.43-5.43,10.92-5.43Z');
                    const path10 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path10.setAttribute('class', 'dt-logo-home');
                    path10.setAttribute('d', 'M289.74,22.58c-1.18-.13-1.96-.2-2.94-.2-5.16,0-7.91,1.9-7.91,7.39v17.65h-10.79V12.25h10.39v6.67c1.63-4.12,4.58-6.73,9.61-6.93.52,0,1.11,0,1.64.07v10.53Z');
                    const path11 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path11.setAttribute('class', 'dt-logo-home');
                    path11.setAttribute('d', 'M326.41,36.38c-3.14,8.3-10.46,11.9-17.52,11.9-11.44,0-19.29-7-19.29-18.63s8.56-18.44,18.83-18.44c11.64,0,18.83,8.89,18.11,21.38h-26.09c.33,4.97,4.18,7.32,8.11,7.32s6.67-1.44,7.91-3.53h9.94ZM300.59,26.05h15.23c-.13-4.38-3.33-6.6-7.39-6.6-3.79,0-7.26,1.96-7.85,6.6Z');
                    const path12 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path12.setAttribute('class', 'dt-logo-home');
                    path12.setAttribute('d', 'M351.12,24.09c0-4.12-2.09-5.62-5.95-5.62-3.46,0-5.88,1.77-6.28,5.16h-10.07c.46-6.6,6.86-12.49,17.26-12.49s15.89,4.05,15.69,14.78l-.13,7.65c-.07,4.58.13,10.85.78,13.86h-10.59l-.13-4.25c-2.61,3.73-6.8,5.1-11.83,5.1-6.67,0-12.16-3.92-12.16-10.33,0-8.63,9.74-11.51,23.4-12.49v-1.37ZM338.83,37.16c0,2.29,2.16,3.79,4.97,3.79,4.31,0,7.58-1.83,7.58-8.3v-.85c-7.52.65-12.55,1.63-12.55,5.36Z');
                    const path13 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path13.setAttribute('class', 'dt-logo-home');
                    path13.setAttribute('d', 'M367.72,2.64h10.79v9.61h7.71v7.65h-7.71v15.76c0,3.33,1.05,3.99,4.64,3.99h3.07v7.78h-6.86c-8.63,0-11.64-1.63-11.64-9.87v-17.65h-5.56v-7.65h5.56V2.64Z');
                    const path14 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path14.setAttribute('class', 'dt-logo-home');
                    path14.setAttribute('d', 'M20.08,29.11l-3.68,3.29L29.96,0h-8.43c-2.59,0-3.29,2.24-3.29,2.24L0,47.4h13.35l6.73-18.29Z');
                    const path15 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path15.setAttribute('class', 'dt-logo-home');
                    path15.setAttribute('d', 'M24.19,18.3l3.68-3.29-13.56,32.4h8.43c2.59,0,3.29-2.24,3.29-2.24L44.27,0h-13.35s-6.73,18.29-6.73,18.29Z');

                    svgType.appendChild(path3);
                    svgType.appendChild(path4);
                    svgType.appendChild(path5);
                    svgType.appendChild(path6);
                    svgType.appendChild(path7);
                    svgType.appendChild(path8);
                    svgType.appendChild(path9);
                    svgType.appendChild(path10);
                    svgType.appendChild(path11);
                    svgType.appendChild(path12);
                    svgType.appendChild(path13);
                    svgType.appendChild(path14);
                    svgType.appendChild(path15);

                    footerLogoType.appendChild(svgType);
              
                    col.appendChild(footerLogo);

                    break;
                case 1:
                    // Create footer-menu
                    col.innerHTML = `
                        <p>NAVIGATION</p>
                        <div class='footer-menu'>
                            <div id='workBtnFooter'><p>Work</p></div>
                            <a href='/studio'><p class='unselectable'>Studio</p></a>
                            <a href='/contact'><p class='unselectable'>Contact</p></a>
                        </div>`;
                    break;
                case 2:
                    // Create address
                    col.innerHTML = `
                        <p>ADDRESS</p>
                        <p>1970 Mayview Drive</p>
                        <p>Los Angeles, CA 90027</p>
                        <p>USA</p>`;
                    break;
                case 3:
                    // Create footer-address
                    col.innerHTML = `
                        <p>GET IN TOUCH</p>
                        <div class='footer-address'>
                        <span class='email-container'></span>
                        <span class='phone-container'></span>
                        </div>`;
                    break;
                case 4:
                    // Create footer-socials
                    col.innerHTML = `
                    <div class='footer-socials'>
                    <a id="footer-linkedin-btn" href='https://www.linkedin.com/company/dual-threat-studio/'>
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
                            <path d="M127.8,0C93,0.1,51.5-0.1,44.4-0.1C10.9-0.1-0.5,6.8-0.2,55.4c0,13.7,0,37.8,0,72.6v128h128c34.8,0,52.6,0,66.3,0
                            c52.5,0,61.7-8.6,61.7-58.9V-0.1C127.8,0,162.6-0.1,127.8,0z M249.6,193.8c0,47.8-8.7,56-58.7,56c-13,0-30,0-63.1,0H6V128
                            c0-33.1,0-56.1,0-69.1C5.7,12.7,16.5,6.1,48.4,6.1c6.8,0,63.2,0.1,79.4,0.1c33.1-0.1,0,0,121.8-0.1V193.8z"/>
                            <rect x="43.5" y="93.9" width="37.2" height="119.7"/>
                            <path d="M62.1,34.4c-11.9,0-21.6,9.7-21.6,21.6s9.6,21.6,21.6,21.6S83.7,67.9,83.7,56S74,34.4,62.1,34.4z"/>
                            <path d="M175.4,90.9c-18.1,0-30.2,9.9-35.2,19.3h-0.5V93.8H104v119.7h37.2v-59.2c0-15.6,3-30.7,22.3-30.7s19.3,17.9,19.3,31.7v58.2
                              H220v-65.6C220,115.7,213,90.9,175.4,90.9z"/>

                      </svg>
                    </a>
                </div>
                <div class='footer-socials'>
                    <a id="footer-insta-btn" href='https://www.instagram.com/dualthreat.studio/' target='_blank'>
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
                         <path d="M127.6,0c-17,0-76.3-0.1-83.4-0.1C10.6-0.2-0.7,6.7-0.4,55.4c0.1,13.7,0,37.8,0,72.6c0,21.3,0,0,0,128c128,0,93.2,0,128,0
                           c34.8,0,52.6,0.1,66.3,0.1c52.5,0,61.7-8.6,61.7-58.9c0-13.7,0-34.4,0-69.2c0-34.8,0,0,0-128.1C127.6,0,162.3-0.1,127.6,0z
                            M249.4,128c0,33.1,0,52.8,0,65.8c0,47.8-8.8,56-58.7,56c-13,0-30-0.1-63.1-0.1c-33.1,0,0,0-121.8,0c0-121.8,0-101.5,0-121.8
                           c0-33.1,0.1-56.1,0-69.1C5.5,12.6,16.3,6,48.2,6c6.8,0,63.2,0.2,79.4,0.1c33.1-0.1,0,0,121.8-0.1C249.4,128,249.4,94.9,249.4,128z"
                           />
                         <path d="M202.8,64.8c9.4,0,17.1-7.7,17.1-17.1c0-9.4-7.6-17.1-17.1-17.1c-9.4,0-17.1,7.7-17.1,17.1
                           C185.7,57.1,193.3,64.8,202.8,64.8z"/>
                         <path d="M127.1,55.8C86.7,55.8,54,88.6,54,129c0,40.4,32.7,73.2,73.1,73.2c40.4,0,73.1-32.7,73.1-73.2
                           C200.3,88.6,167.5,55.8,127.1,55.8z M127.1,176.7c-26.3,0-47.7-21.3-47.7-47.7c0-26.3,21.4-47.7,47.7-47.7
                           c26.3,0,47.7,21.4,47.7,47.7C174.8,155.3,153.5,176.7,127.1,176.7z"/>
                      </svg>
                    </a>
                </div>`;
                    break;
            }

            rowBottom.appendChild(col);

        
        }

        siteFooterInner.appendChild(rowBottom);

        // // Create the copyright div
        // const copyright = document.createElement('div');
        // copyright.className = 'copyright';
        // copyright.innerHTML = '<h6>©2024 dualthreatinc.com. All rights reserved.</h6>';
        // siteFooterInner.appendChild(copyright);

        // // Create the legals div
        // const legals = document.createElement('div');
        // legals.className = 'legals';
        // legals.innerHTML = `
        //     <a href='/legal-notice'><h6>legal notice</h6></a>
        //     <a href='/legal-notice#data-protection' data-scroll-target='data-protection'><h6>data protection</h6></a>`;
        // siteFooterInner.appendChild(legals);

        // Append the entire footer to the main-footer element
        const mainFooter = document.getElementById('main-footer');
        const mainFooterMobile = document.getElementById('main-footer-mobile');
        mainFooter.innerHTML = ''; // Clear any existing content
        // if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        //     mainFooterMobile.appendChild(siteFooterInner);
        //     gsap.set( footerImage1, { yPercent: -50, autoAlpha: 1 });
        //     gsap.to( footerImage1, { 
        //         yPercent: 0,
        //         autoAlpha:1,
        //         scrollTrigger: {
        //             trigger: "#main-footer-mobile",
        //             start: () => "top 100%",
        //             end: () => "top 0",
        //             //markers: true,
        //             scrub: true,
        //         }
        //      })
        //     //  gsap.set( h1, { xPercent: 0 });
        //     //  gsap.to( h1, { 
        //     //     xPercent: 50,
        //     //     scrollTrigger: {
        //     //         trigger: "#main-footer-mobile",
        //     //         start: () => "top 60%",
        //     //         end: () => "top 0",
        //     //         markers: true,
        //     //         scrub: true,
        //     //     }
        //     //  })

           
        // } else {
        //     mainFooter.appendChild(siteFooterInner);
        // }

        if (navigator.platform === 'iPad' || navigator.platform === 'iPhone' || navigator.platform === 'iPod') {
            console.log("This is an iOS device.");
            mainFooterMobile.appendChild(siteFooterInner);
            gsap.set( footerImage1, { yPercent: -50, autoAlpha: 0.8 });
            gsap.to( footerImage1, { 
                yPercent: 0,
                autoAlpha:1,
                scrollTrigger: {
                    trigger: "#main-footer-mobile",
                    start: () => "top 100%",
                    end: () => "top 0",
                    //markers: true,
                    scrub: true,
                }
             })
        } else {
            console.log("This is not an iOS device!");
            mainFooter.appendChild(siteFooterInner);

             //FOOTER NEXT CASE  ANIMATION MOUSE FOLLOW

            // gsap.set(".footerImage", {xPercent: -50, yPercent: -50});
            // mainFooter.addEventListener("mousemove", e => {

            //TEST FOR INITIAL MOUSE POS FIRE on PageLoad to avoid nextIMAGE Jump
            // var x = null;
            // var y = null;
                
            // document.addEventListener('mousemove', onMouseUpdate, false);
            // document.addEventListener('mouseenter', onMouseUpdate, false);
                
            // function onMouseUpdate(e) {
            // x = e.pageX;
            // y = e.pageY;
            // console.log(x, y);

            //}
            // document.addEventListener('mouseover', setInitialMousePos, false);

            // function setInitialMousePos( event ) {
            //     console.log( event.clientX, event.clientY);
            //     document.removeEventListener('mouseover', setInitialMousePos, false);
            // }

            // setInitialMousePos();

            // document.body.addEventListener('click',function(e)
            //     {
            //         console.log("cursor-location: " + e.clientX + ',' + e.clientY);
            //     });


                document.body.addEventListener("mousemove", e => {    
                
                    let targets = gsap.utils.toArray(".nextItem"); 

                    targets.forEach((target, index) => {
                        var tleft = target.getBoundingClientRect().left;
                        var ttop = target.getBoundingClientRect().top;
                        var xMove = target.getAttribute("data-nextspeed");
                        var yMove = target.getAttribute("data-nextspeed");
                        var offset = mainFooter.offsetTop ;

                        //console.log(offset);
                        // console.log(e.pageX);
                        gsap.to(target, {
                            duration: 1,
                            x:  - e.pageX / xMove,
                            y:  - (e.pageY - mainFooter.offsetTop) / yMove,
                            // y: e.pageY ,
                            ease: "power1.out",
                            overwrite: "auto",
                            // delay: 0.1,
                            //stagger: 0.05,
                        });
                    })

                });

                

        }
    
        emailCode();
        const bgClr = footerBg.getAttribute("data-bgclr");
        console.log(bgClr);
        gsap.set('.footer-bg', {backgroundColor: bgClr});

        //Animation FADE IN NextCase Images

      

   


   

    }).catch(error => {
        console.error("Error fetching or processing next case data:", error);
    });

  
 
}




















 // let nextDataLoaded = false; // Flag to track whether nextData has already been loaded

        // // Define the fetchNextData function
        // async function fetchNextData() {
        //     try {
        //         const response = await fetch('../json/next.json');
        //         if (!response.ok) {
        //             throw new Error('Network response was not ok');
        //         }
        //         console.log("Next JSON FETCH succeeded");
        //         return response.json();
        //     } catch (error) {
        //         console.error('Error fetching next JSON:', error);
        //         throw error; // Re-throw the error to handle it further up the call stack
        //     }
        // }
        
        // // Call fetchNextData() and handle the result
        // async function loadNextData() {
        //     try {
        //         const nextData = await fetchNextData();
        //         // Once the JSON data is fetched successfully, you can pass it to footerCases
        //         footerCases(nextData);
        //         nextDataLoaded = true; // Set the flag to true after loading data
        //     } catch (error) {
        //         console.error('Error loading next data:', error);
        //         // Handle the error (e.g., show an error message to the user)
        //     }
        // }
        
        // // Function to be called when you want to trigger footerCases and load next data
        // function triggerFooterCases() {
        //     // Check if nextData has already been loaded
        //     if (!nextDataLoaded) {
        //         // Call loadNextData() only if nextData has not been loaded yet
        //         loadNextData();
        //     }
        // }
        
        // // Example of calling triggerFooterCases() when you want to trigger footerCases
        // triggerFooterCases();










  // export function footerCases(jsonData) { 

  //   console.log(jsonData)
  //    // Get the project number from the main-page element
  //    const projectNumber = document.getElementById('main-page').dataset.project;
  //    console.log(projectNumber);

  //    // Get the URLs from the JSON data
  //    const imageURL1 = jsonData[`proj_${projectNumber}_next1`];
  //    const imageURL2 = jsonData[`proj_${projectNumber}_next2`];
 
  //   //  const imageURL1 = data[`proj_${projectNumber}_next1`];
  //   //  const imageURL2 = data[`proj_${projectNumber}_next2`];
 
  //    // Set the image src attributes
  //   //  document.getElementById('proj_${projectNumber}_next1').src = imageURL1;
  //   //  document.getElementById('proj_${projectNumber}_next2').src = imageURL2;
    
  
   // document.getElementById("main-footer").innerHTML = 
    // "<div id='site-footer-inner' class='site-footer-inner'>"
    // + "<div class='footer-bg'></div>"
    // + "    <img id=`${projectNumber}` src='${imageURL1}' alt='Image 1'>" 
    // + "<div class='row-top'>"
    // // + "    <p>[see whats coming ]</p>"
    // + "    <h1>NEXT PROJECT</h1>"
    // // + "    <p>blalalsdlakhsdlkjdjljsafladhsflhadslf.</p>"
    // + "</div>"
    // + "<div class='row-bottom'>"
    // + "    <div class='col-1-5'>"
    // + "        <div class='footer-logo'>"
    // + "            <svg id='footer-logo' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44.27 47.4'>"
    // + "                <path class='cls-12' d='M20.08,29.1l-3.68,3.29L29.96,0h-8.43c-2.59,0-3.29,2.24-3.29,2.24L0,47.4h13.35l6.73-18.29Z'/>"
    // + "                <path class='cls-12' d='M24.19,18.29l3.68-3.29-13.56,32.4h8.43c2.59,0,3.29-2.24,3.29-2.24L44.27,0h-13.35s-6.73,18.29-6.73,18.29Z'/>"
    // + "            </svg>"
    // + "        </div>"
    // + "    </div>"
    // + "    <div class='col-1-5'>"
    // + "        <p>NAVIGATION</p>"
    // + "    <div class='footer-menu'>"
    // + "        <div id='workBtnFooter' class=''><p>Work</p></div>"
    // + "        <a href='/about'><p class='unselectable'>About</p></a>"
    // + "        <a href='/contact'><p class='unselectable'>Contact</p></a>"
    // + "    </div>"
    // + "    </div>"
    // + "    <div class='col-1-5'>"
    // + "        <p>ADDRESS</p>"
    // + "        <p>3583 Udell Ct</p>"
    // + "        <p>Los Angeles, CA 90027</p>"
    // + "        <p>USA</p>"
    // + "    </div>"
    // + "    <div class='col-1-5'>"
    // + "        <p>GET IN TOUCH</p>"
    // + "    <div class='footer-address'>"
    // + "        <p>hello@dualthreatinc.com</p>"
    // + "    </div>"
    // + "    </div>"
    // + "    <div class='col-1-5'>"
    // + "        <div class='footer-socials'>"
    // + "            <a href='https://www.linkedin.com/company/dual-threat/'><svg id='footer-linkedin-btn' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 252.9 252.45'>"
    // + "                <defs></defs>"
    // + "                <path class='cls-10' d='M4.16,83.82h52.43v168.63H4.16V83.82ZM30.37,0c16.76,0,30.37,13.62,30.37,30.4s-13.62,30.38-30.37,30.38S0,47.17,0,30.4,13.58,0,30.37,0'/>"
    // + "                <path class='cls-10' d='M89.46,83.82h50.27v23.06h.72c7-13.26,24.1-27.23,49.59-27.23,53.07,0,62.87,34.91,62.87,80.32v92.49h-52.39v-82c0-19.56-.34-44.71-27.23-44.71s-31.44,21.32-31.44,43.31v83.41h-52.39V83.82Z'/>"
    // + "            </svg></a>"
    // + "        </div>"
    // + "        <div class='footer-socials'>"
    // + "            <a href='https://www.instagram.com/dualthreatinc/' target='_blank'><svg id='footer-insta-btn' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>"
    // + "                <defs></defs>"
    // + "                <path class='cls-11' d='M12,2.16c3.2,0,3.58.01,4.85.07,3.25.15,4.77,1.69,4.92,4.92.06,1.27.07,1.65.07,4.85s-.01,3.58-.07,4.85c-.15,3.23-1.66,4.77-4.92,4.92-1.27.06-1.64.07-4.85.07s-3.58-.01-4.85-.07c-3.26-.15-4.77-1.7-4.92-4.92-.06-1.27-.07-1.64-.07-4.85s.01-3.58.07-4.85c.15-3.23,1.66-4.77,4.92-4.92,1.27-.06,1.64-.07,4.85-.07ZM12,0c-3.26,0-3.67.01-4.95.07C2.7.27.27,2.69.07,7.05c-.06,1.28-.07,1.69-.07,4.95s.01,3.67.07,4.95c.2,4.36,2.62,6.78,6.98,6.98,1.28.06,1.69.07,4.95.07s3.67-.01,4.95-.07c4.35-.2,6.78-2.62,6.98-6.98.06-1.28.07-1.69.07-4.95s-.01-3.67-.07-4.95c-.2-4.35-2.62-6.78-6.98-6.98C15.67.01,15.26,0,12,0ZM12,5.84c-3.4,0-6.16,2.76-6.16,6.16s2.76,6.16,6.16,6.16,6.16-2.76,6.16-6.16-2.76-6.16-6.16-6.16ZM12,16c-2.21,0-4-1.79-4-4s1.79-4,4-4,4,1.79,4,4-1.79,4-4,4ZM18.41,4.15c-.8,0-1.44.64-1.44,1.44s.65,1.44,1.44,1.44,1.44-.64,1.44-1.44-.64-1.44-1.44-1.44Z'/>"
    // + "            </svg></a>"
    // + "        </div>"
    // + "    </div>"
    // + "</div>"
    // + "<div class='copyright'>"
    // + "    <h6>©2024 dualthreatinc.com. All rights reserved.</h6>"
    // + "</div>"
    // + "<div class='legals'>"
    // + "    <a href='/legal-notice'><h6>legal notice</h6></a>"
    // + "    <a href='/legal-notice#data-protection' data-scroll-target='data-protection'><h6>data protection</h6></a>"
    // + "</div>"
    // + "</div><!-- END Footer-INNER -->"
  // } 