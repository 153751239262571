import { gsap, ScrollTrigger } from "gsap/all";



export function claim() {


        let claim = document.getElementById("claim");
        gsap.set(claim, {yPercent:1, autoAlpha:0})
        gsap.to(claim, {autoAlpha:1,y:1,x:1,z:1,transformOrigin:"0% 100%", duration: 0.15})
        gsap.to(claim, {  yPercent: -100, ease: "none",
                             scrollTrigger: {
                            trigger: claim,
                             //scroller: "#smooth-wrapper",
                             id: "heroText",
                             start: ()=> "top 65%",
                             end: ()=>  "35%",
                             invalidateOnRefresh: true,
                             //onUpdate: function(self) {
                                // Update ScrollTrigger when scrolling within the inner container
                               // self.scroll(self.scroll().x, innerContainer.scrollTop); },
                             scrub: true,
                            //pin: true,
                            //  pinSpacing: false,
                            // markers: true,
                            }
                    })
                

        
}

