import moment from 'moment-timezone';
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin, CustomEase, ScrollSmoother } from "gsap/all";

export function curtain() {

  var crt = document.getElementById("curtain");
  crt.style.display = "block";
  gsap.to(crt, { duration: 0.25, autoAlpha:1});
  
}

export function clock() {
  //CLOCK

  window.setInterval(function () {

    var now = moment() // current timestamp, using your machine's time zone
    //var nowLA = now.tz('America/Los_Angeles').format('M/D/YY – h:mm:ss a'); // converted to LA time zone
    var nowLA = now.tz('America/Los_Angeles').format('h:mm:ss A'); 
  
    //nowLA.format('M D YY, h:mm:ss');  // September 13th 2015, 10:43:17 am
    // console.log(now);
    //moment().format("[Today is] dddd");  
    //console.log(nowLA);
  
    document.getElementById("date-time").innerHTML = nowLA;
  
  }, 1000)


}
  export function capAni() {

         // This pen cleverly utilizes SVG filters to create a "Morphing Text" effect. 
                // Essentially, it layers 2 text elements on top of each other, and blurs 
                // them depending on which text element should be more visible. Once the blurring 
                // is applied, both texts are fed through a threshold filter together, which produces 
                // the "gooey" effect. Check the CSS - Comment the #container rule's filter out to see how the blurring works!
            

                const elts = {
                  text1: document.getElementById("text1"),
                  text2: document.getElementById("text2")
                };
  
                // The strings to morph between. You can change these to anything you want!
                const texts = [
                  "Capabilities",
                  "art direction",
                  "photography",
                  "and whatever",
                  "comes to our",
                  "mind",
                  
                ];
  
                // Controls the speed of morphing.
                const morphTime = 1.5;
                const cooldownTime = 0.75;
  
                let textIndex = texts.length - 1;
                let time = new Date();
                let morph = 0;
                let cooldown = cooldownTime;
  
                elts.text1.textContent = texts[textIndex % texts.length];
                elts.text2.textContent = texts[(textIndex + 1) % texts.length];
  
                function doMorph() {
                  morph -= cooldown;
                  cooldown = 0;
                  
                  let fraction = morph / morphTime;
                  
                  if (fraction > 1) {
                    cooldown = cooldownTime;
                    fraction = 1;
                  }
                  
                  setMorph(fraction);
                }
  
                // A lot of the magic happens here, this is what applies the blur filter to the text.
                function setMorph(fraction) {
                  // fraction = Math.cos(fraction * Math.PI) / -2 + .5;
                  
                  elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
                  elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;
                  
                  fraction = 1 - fraction;
                  elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
                  elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;
                  
                  elts.text1.textContent = texts[textIndex % texts.length];
                  elts.text2.textContent = texts[(textIndex + 1) % texts.length];
                }
  
                function doCooldown() {
                  morph = 0;
                  
                  elts.text2.style.filter = "";
                  elts.text2.style.opacity = "100%";
                  
                  elts.text1.style.filter = "";
                  elts.text1.style.opacity = "0%";
                }
  
                // Animation loop, which is called every frame.
                function animate() {
                  requestAnimationFrame(animate);
                  
                  let newTime = new Date();
                  let shouldIncrementIndex = cooldown > 0;
                  let dt = (newTime - time) / 1000;
                  time = newTime;
                  
                  cooldown -= dt;
                  
                  if (cooldown <= 0) {
                    if (shouldIncrementIndex) {
                      textIndex++;
                    }
                    
                    doMorph();
                  } else {
                    doCooldown();
                  }
                }
  
                // Start the animation.
                animate();
  
  
  
  
  
  
        
  
  
  
      
    

  };



  //CONTACT BUTTON
  export function contactBtnFunc () {
    let contactBtn = document.getElementById('contactBtn');
    let contactCont = document.getElementById("contact-container-inner");
    let contactContInner = document.getElementById("contact-container-inner");
    let contactContOuter = document.getElementById("contact-container-outer");
    let triggerIn = document.getElementById("secHero");
    let triggerOut = document.getElementById("footerTrigger");


   

    gsap.set(contactContInner, { yPercent: 150}); 

    gsap.to(contactContInner, {  yPercent: 5, autoAlpha:1, duration: 0.25,  ease: "power2.inOut",
                         scrollTrigger: {
                        trigger: triggerIn,
                         //scroller: "#smooth-wrapper",
                         id: "contactIn",
                         start: ()=> "bottom 0%",
                         end: ()=>  "bottom 50%",
                         invalidateOnRefresh: true,
                         toggleActions: "play none reverse none",
                         //onUpdate: function(self) {
                            // Update ScrollTrigger when scrolling within the inner container
                           // self.scroll(self.scroll().x, innerContainer.scrollTop); },
                         //scrub: true,
                         //pin: true,
                         //pinSpacing: false,
                         
                         pinType: "fixed",
                         //markers: true,
                        }
    })
    gsap.to(contactContOuter, {  yPercent: -120, duration: 0.25, ease: "power2.inOut",
                        scrollTrigger: {
                        trigger: triggerOut,
                        //scroller: "#smooth-wrapper",
                        id: "contactOut",
                        start: ()=> "top 100%",
                        end: ()=>  "top 100%",
                        invalidateOnRefresh: true,
                        toggleActions: "play none reverse none",
                        //onUpdate: function(self) {
                          // Update ScrollTrigger when scrolling within the inner container
                          // self.scroll(self.scroll().x, innerContainer.scrollTop); },
                        //scrub: true,
                        //pin: true,
                        //pinSpacing: false,
                        
                        pinType: "fixed",
                        //markers: true,
                      }
})

}


  //INDEX BUTTON
//   export function indexBtnFunc () {
//     let indexBtn = document.getElementById('indexBtn');
//     let indexCont = document.getElementById("index-container-inner");
//     let indexContInner = document.getElementById("index-container-inner");
//     let indexContOuter = document.getElementById("index-container-outer");
//     let triggerIn = document.getElementById("secHero");
//     let triggerOut = document.getElementById("footerTrigger");


   

//     gsap.set(indexContInner, { yPercent: 150}); 

//     gsap.to(indexContInner, {  yPercent: 0, autoAlpha:1, duration: 0.25,  ease: "power2.inOut",
//                          scrollTrigger: {
//                         trigger: triggerIn,
//                          scroller: "#smooth-wrapper",
//                          id: "indexIn",
//                          start: ()=> "bottom 0%",
//                          end: ()=>  "bottom 50%",
//                          invalidateOnRefresh: true,
//                          toggleActions: "play none reverse none",
//                          //onUpdate: function(self) {
//                             // Update ScrollTrigger when scrolling within the inner container
//                            // self.scroll(self.scroll().x, innerContainer.scrollTop); },
//                          //scrub: true,
//                          //pin: true,
//                          //pinSpacing: false,
                         
//                          pinType: "fixed",
//                          //markers: true,
//                         }
//     })
//     gsap.to(indexContOuter, {  yPercent: -120, duration: 0.25, ease: "power2.inOut",
//                         scrollTrigger: {
//                         trigger: triggerOut,
//                         scroller: "#smooth-wrapper",
//                         id: "indexOut",
//                         start: ()=> "top 100%",
//                         end: ()=>  "top 100%",
//                         invalidateOnRefresh: true,
//                         toggleActions: "play none reverse none",
//                         //onUpdate: function(self) {
//                           // Update ScrollTrigger when scrolling within the inner container
//                           // self.scroll(self.scroll().x, innerContainer.scrollTop); },
//                         //scrub: true,
//                         //pin: true,
//                         //pinSpacing: false,
                        
//                         pinType: "fixed",
//                         //markers: true,
//                       }
// })

// }

export function emailCode() {

var part1 = "hello";
var part2 = Math.pow(2,6);
var part3 = String.fromCharCode(part2);
var part4 = "dualthreat.studio";
var part5 = part1 + String.fromCharCode(part2) + part4;
// document.getElementById("email-container").innerHTML = "<a href=" + "mai" + "lto" + ":" 
// + part5 + ">" + part1 + part3 + part4 + "</a>";
var emails = document.querySelectorAll(".email-container");
var i;
for (i = 0; i < emails.length; i++) {
  emails[i].innerHTML = "<a href=" + "mai" + "lto" + ":"  + part5 + "><p>" + part1 + part3 + part4 + "</p></a>";
}

var part6 = "+1";
var part7 = ".";
var part8 = "310.";
var part9 = "666.";
var part10 = "2117";
// document.getElementById("email-container").innerHTML = "<a href=" + "mai" + "lto" + ":" 
// + part5 + ">" + part1 + part3 + part4 + "</a>";
var phones = document.querySelectorAll(".phone-container");
var i2;
for (i2 = 0; i2 < emails.length; i2++) {
  phones[i2].innerHTML = "<a href=" + "tel" + ":"  + part6 + part7 + part8 + part9 + part10 + "><p>" + part6 + part7 + part8 + part9 + part10 + "</p></a>";
}

};



export function decode() {

var encEmail = "aGVsbG9AdGhlc3RlZmYuY29t";
const form = document.getElementById("contact_mail");
form.setAttribute("href", "mailto:".concat(atob(encEmail)));





//   return a.replace(/[a-zA-Z]/g, function(c){
//     return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) 
//                                ? c : c - 26);
//   })
// }; 
// function openMailer(element) {
// var y = decode("znvygb:orahgmre@qbznva.qr");
// element.setAttribute("href", y);
// element.setAttribute("onclick", "");
// element.firstChild.nodeValue = "Open email software";
};



// export function langSelect() { 

// var language; 
// getLanguage = function () {
// (localStorage.getItem('language') == null) ? setLanguage('en') : false;
// $.ajax({ 
// url:  '/language/' +  localStorage.getItem('language') + '.json', 
// dataType: 'json', async: false, dataType: 'json', 
// success: function (lang) { language = lang } });
// }

// setLanguage = function (lang) {
// localStorage.setItem('language', lang);
// }
// }

export function mouseFollowImages() {

  // $(document).ready(function(){
    
  //   $('body.shp-case').mousemove(function(e){
  //    let mouseX = e.pageX;
  //    let mouseY = e.pageY;
  //    console.log(mouseX);
      
  //     let images = document.getElementsByClassName("c50ss");
  //     console.log(images);
  //     $(images).css({
  //     //$(this).children('.item_image-wrap2').css({
  //      'transform': 'translate(' + mouseX / -30 + 'px ,' + mouseY / -30 + 'px)'
  //      //'transform': 'translate(' + mouseX / -80 + 'px )'
       
  //     })
  //     let images2 = document.getElementsByClassName("c100lc");
  //     console.log(images2);
  //     $(images2).css({
  //     //$(this).children('.item_image-wrap2').css({
  //      'transform': 'translate(' + mouseX / -80 + 'px ,' + mouseY / -80 + 'px)'
  //      //'transform': 'translate(' + mouseX / -80 + 'px )'
       
  //     })

  //     let images3 = document.getElementsByClassName("c50bp");
  //     console.log(images3);
  //     $(images3).css({
  //     //$(this).children('.item_image-wrap2').css({
  //      'transform': 'translate(' + mouseX / -40 + 'px ,' + mouseY / -40 + 'px)'
  //      //'transform': 'translate(' + mouseX / -80 + 'px )'
       
  //     })

      

  //    });
  //  });



 
  
  }


  export function triggerLanguageTitles() {




      //const webGLSlider = document.querySelector('#shpSlider');
      
      // Split it twice to be able to reveal it from hidden overflow
      shSlider.childSplit = new SplitText('.slider-heading-down', {
        type: 'lines',
        linesClass: 'split-child'
    });
    shSlider.parentSplit = new SplitText('.slider-heading-down', {
        type: 'lines',
        linesClass: 'split-parent'
    });


    // The text reveal animation
    shSlider.anim = gsap.from(shSlider.childSplit.lines, {
        duration:1,
        paused: true,
        yPercent: 100, 
        stagger: 0.3,
        ease: "expo.out",
    });
    // if(firstRun) {
    
          shSlider.anim.play();
    //     // firstRun = false;
    // };



  }


//   export function bgTrans() {


 
// var $body = document.getElementsByClassName('backgrTrans')[0];
// // var colors = [
// //   [ 37,  81, 186], //blue
// //   [  9, 199, 125], //green
// //   [223,  44,  60], //red
// //   [168,  17,  99], //purple
// //   [ 45, 175, 230]  //light blue
// // ];
// var colors = [
//   [ 155, 124, 66], //red
//   [ 50, 50, 30], //green
//   [ 0, 0, 0], //blue
//   [ 173, 136, 47], //purple
//   [ 32, 29, 34]  //light blue
// ];
// var colorLen = colors.length;
// var step = 0;
// var colorIndices = new Array(colorLen - 1).join().split(',').map(function (v, i) {
//   return i;
// });
// var gradientSpeed = 0.001; //transition speed

// var getColor = function getColor(color1, color2) {
//   var r_step = 1 - step;
//   var rgb = color1.map(function (v, i) {
//     return ~~(r_step * color1[i] + step * color2[i]);
//   }).join();
//   return 'rgb(' + rgb + ')';
// };

// var upodateColorIdx = function upodateColorIdx() {
//   colorIndices.forEach(function (v, i) {
//     if (i % 2) {
//       colorIndices[i] = ~~(colorIndices[i - 1] + Math.random() * (colorLen - 1) + 1) % colorLen;
//     } else {
//       colorIndices[i] = colorIndices[(i + 1) % (colorLen - 1)];
//     }
//   });
// };

// var Gradient = function Gradient() {
//   var colorNow = colorIndices.map(function (v) {
//     return colors[v];
//   });
//   var color1 = getColor(colorNow[0], colorNow[1]);
//   var color2 = getColor(colorNow[1], colorNow[2]);
//   var color3 = getColor(colorNow[2], colorNow[3]);

//   $body.style.backgroundImage = 'linear-gradient(to right bottom, ' + color1 + ', ' + color3 +')';

//   step += gradientSpeed;
//   if (step >= 1) {
//     step %= 1;
//     upodateColorIdx();
//   }
// };

// window.requestAnimFrame = function () {
//   return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
//     window.setTimeout(callback, 1000 / 60);
//   };
// }();

// (function animloop() {
//   requestAnimFrame(animloop);
//   Gradient();
// })();


//   }




  export function videoPlaybackHome() {

     //VIDEO CONTROL FUNCTION FOR INDEX PAGE / OBSERVER


     let videos = document.querySelectorAll(".video"); 
      videos.autoplay = false;
     // console.log(videos);
    
    videos.forEach(function(video) {
     


      //  console.log(videos)
       console.log(video)
       $(video).prop({
         controls: false,
         controlslist: "nodownload",
         loop: true,
         muted: true,
       });
 



       const observerVids = new window.IntersectionObserver(
         ([entry]) => { 
           const poster = video.closest('.item_video').querySelector('.video-poster');
             //console.log(poster);
             if (entry.isIntersecting) {
                   //IMPORTANT 0.0000001 because scroll issue on iOS if poster not above video
                     

                         //console.log("video los"); 
                         if (video.paused) {
                             $(video).prop("muted", true);
                             video.play();
                             video.onplaying = function() {
                              console.log("The video is now playing");
                              gsap.to(poster, {autoAlpha: 0.000000001, duration: 0.3, delay:0.25,
                                onComplete: () => {
                                  poster.classList.add("removed");
                                }
                              });
                             
                            };
                            //  
                            
                            //console.log(video.innerHTML + " video play"); 

                        //  setTimeout(function() {
                        //      poster.classList.add("removed");
                        //  }, 1000);

                         }
                        
                  
             } else {
                 gsap.to(poster, {autoAlpha: 1, duration: 0.25,
                     onPlay: () => {
                         console.log(video.innerHTML + " video stop"); 
                         video.pause();
                     }
                     
                 });

                 if (poster.classList.contains("removed")) {

                  gsap.set(poster, {autoAlpha: 0.0000000001});

                 }
             }
         }, 
         {    
            //  threshold: 0.2,
            root: document.querySelector('smooth-wrapper'),
            rootMargin: "0px 0px -200px 0px",
            delay: 200,
            
         }
     );


    //  observerVids.root.style.border = "20px solid #44aa44";
     observerVids.observe(video);
    //  document.getElementById('pause').addEventListener("click", function() { video.pause(); });




    
 });

 setTimeout(function () {
 var introVid = document.getElementById("introVid");

 if (typeof(introVid) != 'undefined' && introVid != null)
  {
  

//  console.log(introVid);

    if (introVid.paused) {
        gsap.to("#playBtn", {autoAlpha:1, duration: 0.3});
    }
    document.getElementById("playBtn").addEventListener("click", function() {
      console.log('clicked')
      if (introVid.paused) {
        introVid.play();
      
      } else {
        introVid.pause();
      }

    });
      // Exists.
  }

 }, 1000);

}


export function videoControl() {
  // VIDEO //
  console.log("Fn VIDEOCONTROLS loaded");

  let vid = document.getElementById("noautoplay");

  if (vid) {
  if (vid.paused) { 
     
    
    gsap.set('.vid-play',{yPercent: -110, duration: 0.3})
    gsap.set('.vid-pause',{yPercent: -110, duration: 0.3})

    }else{
     
      gsap.set('.vid-play',{yPercent: 0, duration: 0.3})
      gsap.set('.vid-pause',{yPercent: 110, duration: 0.3})
    }



if (vid.muted) { 
     
  console.log("vid is muted")
  gsap.set('.sound-on',{autoAlpha:0,})
  gsap.set('.sound-off',{autoAlpha:1,})

  }else{

    gsap.set('.sound-on',{autoAlpha:1, })
    gsap.set('.sound-off',{autoAlpha:0,})
  }



  document.getElementById('playreel').onclick = function () {

   if (vid.paused) { 
     
     vid.play();
     gsap.to('.vid-play',{yPercent: -110, duration: 0.3})
     gsap.to('.vid-pause',{yPercent: -110, duration: 0.3})

     }else{
       vid.pause();
       gsap.to('.vid-play',{yPercent: 0, duration: 0.3})
       gsap.to('.vid-pause',{yPercent: 110, duration: 0.3})
     }

 }

 document.getElementById('soundreel').onclick = function () {

   if (vid.muted) { 
     
     vid.muted = false;
     gsap.to('.sound-on',{autoAlpha:1, duration: 0.3})
     gsap.to('.sound-off',{autoAlpha:0, duration: 0.3})

     }else{

       vid.muted = true;
       gsap.to('.sound-on',{autoAlpha:0, duration: 0.3})
       gsap.to('.sound-off',{autoAlpha:1, duration: 0.3})
     }

 }

 //console.log(casesVids);
      // function openFullscreen() {
        
      // }
    
      document.getElementById('fullscreen').onclick = function () {

          //vid.requestFullscreen();
          vid.webkitEnterFullscreen();
        //   var casesVids = document.getElementsByTagName("video");
          
        //   if (myDocument.requestFullscreen) {
        //     myDocument.requestFullscreen();
        // } 
        // else if (myDocument.msRequestFullscreen) {
        //     myDocument.msRequestFullscreen();
        // } 
        // else if (myDocument.mozRequestFullScreen) {
        //     myDocument.mozRequestFullScreen();
        // }
        // else if(myDocument.webkitRequestFullscreen) {
        //     myDocument.webkitRequestFullscreen();
        // }

            // if (casesVids.requestFullscreen) {
            //   casesVids.requestFullscreen();
            // } else if (casesVids.webkitRequestFullscreen) { /* Safari */
            // casesVids.webkitRequestFullscreen();
            // } else if (casesVids.msRequestFullscreen) { /* IE11 */
            // casesVids.msRequestFullscreen();
            // }
          }

        }else{
          console.log("not vid found on this site")
        }
} //END VIDEO CONTROL FUNCTION  


// export function footerAni() {

//   // ScrollTrigger.normalizeScroll(true);
//   //ScrollTrigger.normalizeScroll({ allowNestedScroll: true })
//   //ScrollTrigger.normalizeScroll({ target: "#smooth-wrapper", allowNestedScroll: true })
//   //ScrollTrigger.normalizeScroll({ target: "#smooth-wrapper", allowNestedScroll: true, type: "touch,scroll" });
//   //document.querySelector("#smooth-wrapper").addEventListener("scroll", ScrollTrigger.update);
//   ScrollTrigger.config({ ignoreMobileResize: true})


//     let footer = document.getElementById("main-footer");
//     let footerInner = document.getElementById("site-footer-inner");
//     let trigger =  document.getElementById("spacer");


//     // ScrollTrigger.create({
//     //   trigger: footer,
//     //   scroller: "#smooth-wrapper",
//     //   pin: true,
//     //   pinSpacing: false,
//     //   start: "top top",
//     //   end: "200%",
//     //   markers: true,
//     // });

//     // gsap.set(footer, {yPercent: -100});

   

//     // if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
//     //  alert("This is an iOS device.");
//     // } else {


//   //   gsap.to(footer, {
//   //     yPercent: 0, 
    
//   //     // ease: "none",
//   //     scrollTrigger: {
//   //       trigger: footer,
//   //       //scroller: "#smooth-wrapper",
//   //       start: ()=> "top top",
//   //       end: ()=>  "+=100%",
//   //       pin: true,
//   //       // toggleActions: "play none reverse none",
//   //       //pinSpacing:true,
//   //       scrub: true,
//   //       //pinType: "fixed",   //IF FIXED iOS Looks good but no scroll possible anymore
//   //       invalidateOnRefresh: true,
//   //      // markers:true,

//   //     }
//   //   });

//   // //   if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
//   // //     // alert("This is an iOS device.");
//   // // } else {
//   // //   //  alert("This is not an iOS device!");
   
//   // // }
   
//   // gsap.set(footerInner, {yPercent: 50, autoAlpha:0});
//   // const uncover = gsap.timeline({ paused:true })

//   // uncover
//   // .to(footerInner, { yPercent: 0, autoAlpha:1 });

//   // ScrollTrigger.create({  
//   //   trigger: footer,
//   //   scroller: "#smooth-wrapper",
//   //   start: 'top top',
//   //   end: '+=100%',
//   //   animation: uncover,
//   //   // toggleActions: "play none reverse none",
//   //   invalidateOnRefresh: true,


//   //   //pin: true,
//   //   //pinSpacing: false,
//   //   // pinType: "fixed",
//   //   scrub: true,  
//   //   //markers: true,
//   // })




//   // console.log("FOOTER ANIs") 
//   // gsap.set(footer, {yPercent:-25});
//   // ScrollTrigger.create({
//   //     trigger: ".footer",
//   //     scroller: "#smooth-wrapper",
//   //     start:() => 'top 50%',
//   //     // end: () => 'bottom 1%',
//   //     scrub:true,
//   //     //id: "nextCaseBackground",
//   //     //markers: true,
//   //     invalidateOnRefresh: true,
//   //     pinSpacing: false,
//   //     onEnter: () => {
//   //         //rootBgColor = getComputedStyle(document.documentElement).getPropertyValue('--bg-color');
//   //         gsap.to(".footer-inner", { duration: 0.5,yPercent:0, autoAlpha: 1, ease: "power2.in"});
//   //        //gsap.to(".pagination", {duration:0.2, autoAlpha:1 });
//   //     },
//   //     onLeaveBack: () => {

//   //       gsap.to(".footer-inner", { duration: 0.3, yPercent:25, autoAlpha: 0});
//   //         //gsap.to(".pagination", {duration:0.2, autoAlpha:0 });
//   //         // console.log("hier???");
//   //     },
//   // });

//   // gsap.set('.footer', { yPercent: 20 })

//   //       const uncover = gsap.timeline({ paused:true })

//   //       uncover
//   //       .to('.footer', { yPercent: 0, ease: 'none' })
//   //       ;

//   //       ScrollTrigger.create({  
//   //         trigger: '.footer',
//   //         scroller: "#smooth-wrapper",
//   //         start: 'top top',
//   //         end: '+=100%',
//   //         animation: uncover,
//   //         pin: true,
//   //         pinSpacing: false,
//   //         scrub: true,  
//   //         markers: true,
//   //       })
  
  
// }