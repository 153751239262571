console.log("preloader.js triggered");  

export function preloader() { document.getElementById("preloader").innerHTML = "<div class='loading-screen'></div>"
+ "<div id='preloaderIcon' class='logo-container-icon'>"
+"      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>"
+ "        <defs>"
+ "            <style>"
+ "                .icon {"
+ "                  fill: none;"
+ "                  stroke: #FFFFFF;"
+ "                  stroke-miterlimit: 50;"
+ "                }"
+ "              </style>"
+ "        </defs>"

+"      <path id='dash1' class='dash' d='M75.42,136.7L132.64,0h-35.56c-10.93,0-13.86,9.44-13.86,9.44L6.21,200h56.32l28.4-77.19-15.51,13.9Z' />"
+"      <path id='dash2' class='dash' d='M136.68,0l-28.4,77.19,15.51-13.9-57.22,136.7h35.56c10.93,0,13.86-9.44,13.86-9.44L193,0h-56.32Z' />"
+"      <path id='dash3' class='dash' d='M36.87,200L117.44,0h45.37l-80.57,200h-45.37Z'/>"
// + "        <polygon class='icon' points='20.66 .5 .74 49.79 11.96 49.79 31.87 .5 20.66 .5'/>"
// + "        <polygon class='icon' points='35.54 .5 15.62 49.79 26.84 49.79 46.76 .5 35.54 .5'/>"
+ "    </svg>"
+ "</div>"
} 


export function transition() { document.getElementById("pagetrans").innerHTML = "<div class='loading-screen'></div>"
+ "<div id='preloaderIcon' class='logo-container-icon'>"
+"      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>"
+ "        <defs>"
+ "            <style>"
+ "                .icon {"
+ "                  fill: none;"
+ "                  stroke: #FFFFFF;"
+ "                  stroke-miterlimit: 50;"
+ "                }"
+ "              </style>"
+ "        </defs>"

+"      <path id='dash1' class='dash' d='M75.42,136.7L132.64,0h-35.56c-10.93,0-13.86,9.44-13.86,9.44L6.21,200h56.32l28.4-77.19-15.51,13.9Z' />"
+"      <path id='dash2' class='dash' d='M136.68,0l-28.4,77.19,15.51-13.9-57.22,136.7h35.56c10.93,0,13.86-9.44,13.86-9.44L193,0h-56.32Z' />"
+"      <path id='dash3' class='dash' d='M36.87,200L117.44,0h45.37l-80.57,200h-45.37Z'/>"
// + "        <polygon class='icon' points='20.66 .5 .74 49.79 11.96 49.79 31.87 .5 20.66 .5'/>"
// + "        <polygon class='icon' points='35.54 .5 15.62 49.79 26.84 49.79 46.76 .5 35.54 .5'/>"
+ "    </svg>"
+ "</div>"
} 

