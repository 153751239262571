import { gsap, ScrollTrigger } from "gsap/all";
// import jsonData from '../json/next.json';



// //ANIMATIONEN IMAGES
// export function updateFooterImages() {
//     const projectNumber = document.getElementById('main-page').dataset.project;
//     const imageURL1 = jsonData[`proj_${projectNumber}_next1`];
//     const imageURL2 = jsonData[`proj_${projectNumber}_next2`];

//     document.getElementById('proj_6_next1').src = imageURL1;
//     document.getElementById('proj_6_next2').src = imageURL2;
// }

// window.addEventListener('scroll', function() {
//     if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
//         updateFooterImages();
//     }
// });


export function imagesCaseAni() {


    window.addEventListener('orientationchange', async () => {
       
        ScrollTrigger.refresh()
      })
  
   
    var d = window.matchMedia("(min-width: 1025px)");
    var iPadPro = window.matchMedia("(min-device-width: 1024px) and (max-device-width: 1366px");
    var tl = window.matchMedia("(min-width:950px) and (max-width: 1024px) and (orientation: landscape)");
    var tp = window.matchMedia("(min-width: 768px) and (orientation: portrait)");
    var ml = window.matchMedia("(min-width: 768px) and (max-width: 1023px) and (orientation: landscape) ");
    var mp = window.matchMedia("(min-width: 320px) and (max-width: 767px) and (orientation: portrait) ");



   //var isMobileScreenWidth = ((screen.width / window.devicePixelRatio) < 768)
      
    // alert(screen.width);
    // alert( window.devicePixelRatio);
    // alert(screen.width, window.devicePixelRatio, isMobileScreenWidth);

    if (iPadPro.matches) {
      //  alert("iPad Pro");

    }

    //introTextFade ();
    //pinHero();
    //pinInfo();
    if (d.matches || tl.matches || tp.matches) {
    // alert("TETS")
    introTextFade ();
    //pinTitle();
    pinInfo();
    // pinBackHOME();
    backLogo();


    }   else if (mp.matches)    {

        introTextFade ();
        backLogo();


    }




    function introTextFade () {
        let introText = document.getElementById("intro-text");
        //console.log(introText);
        gsap.to(introText, {autoAlpha:0, ease: "none",
                             scrollTrigger: {
                             trigger: ".case-hero",
                            // scroller: "#smooth-wrapper",
                             id: "introText",
                             start: ()=> "top 30% ",
                             end: ()=>  "top 10%",
                             invalidateOnRefresh: true,
                            //  onUpdate: function(self) {
                            //     // Update ScrollTrigger when scrolling within the inner container
                            //     self.scroll(self.scroll().x, innerContainer.scrollTop); },
                             scrub: true,
                             immediateRender: false,
                             //pin: true,
                            //markers: true,
                            }
                    })
                }
    //introTextFade();

    
    function pinTitle () {
        let heroText = document.getElementById("full-width-text");
        let row1 = document.getElementById("intro-row1");
        // let row2 = document.getElementById("intro-row2");
        // //console.log(heroText);
        // gsap.set(row2, { yPercent: 15 });
        // gsap.set(row1, { yPercent: 50 });
        gsap.to(heroText, {  yPercent: -25, ease: "none",
                             scrollTrigger: {
                            trigger: heroText,
                             //scroller: "#smooth-wrapper",
                             id: "heroText",
                             start: ()=> "top 20%",
                             end: ()=>  "100%",
                             invalidateOnRefresh: true,
                             //onUpdate: function(self) {
                                // Update ScrollTrigger when scrolling within the inner container
                               // self.scroll(self.scroll().x, innerContainer.scrollTop); },
                             scrub: true,
                            //pin: true,
                            //  pinSpacing: false,
                            //markers: true,
                            }
                    })
                }
    //pinHero();




    function pinInfo () {
            let pinInfoText = document.getElementById("case-info");
            const sections = document.querySelectorAll('section');
            const numSections = sections.length;
            const adjust = 400;


            /*helper function to store height of fixed element at page refresh */
            let elem = document.getElementById("case-info-inner");
            let elemCont = elem.getBoundingClientRect();
            //console.log(elemCont);
            // localStorage.setItem('itemHeight', elemCont.height);


            gsap.to(elem, { yPercent: -175,
                scrollTrigger: { 
                    trigger: "#footerTrigger",
                    start: "top 50%",
                    scrub: true,
                    //markers: true,
                
                }
            })

            //// FUNCTION TO ADD ELEMENT OUTSIDE TO BE FIXED ELEMENT
            let content = document.querySelector("#smooth-content");
            
            const fixedItem = () => {  
                const item = document.getElementById("case-info-inner");
                let container = document.querySelector(".case-info-cont");
                let isPinned = false; // Track if the item is pinned
                let wasBelowTrigger = false; // Track if the item was previously below the trigger point


                let itemTl = gsap.timeline({
                scrollTrigger: {
                    pinnedContainer: "#smooth-wrapper",
                    trigger: container,
                    // toggleClass: "fixed",
                   // markers: true,
                    //start: "top 100%", // START PIINING FROM BEGINNING
                    start: () => "top 15%",
                    endTrigger: "#footerTrigger",
                    //end: "top 50%",
                    end: () => "bottom -50%",
                    invalidateOnRefresh: true,
                    onToggle: self => {
                        toggleItem(self.isActive);
                       
                    }
                    }
                });

           

            function toggleItem(pinned) {

                
            // if (!pinned) {
                        
            //             gsap.set(item, { clearProps: true });
            //             container.append(item);
            //             item.classList.remove("fixed");      
            //             return;
            //         } 

            let itemCont = document.getElementById("case-info-inner");
            let smoothContent = content.getBoundingClientRect();
            let smoothWrapper = container.getBoundingClientRect();
            
                           
            if (!pinned) {
                if (!pinned) {
                // Animate the element out only if it was previously pinned
                    if (isPinned) {
                        
                            gsap.set(item, { clearProps: true });
                            container.append(item);
                            item.classList.remove("fixed");

                            }
                        
                    }
                    isPinned = false;
                    return;
               
            }
             
            const pinInfo = pinInfoText.getBoundingClientRect();
            const pinInfoTop = pinInfo.top;
            //console.log("pinInfoTop after PIN  " + pinInfoTop);
            
            // let smoothContent = content.getBoundingClientRect();
            // let smoothWrapper = container.getBoundingClientRect();
            
            /* Calculation for the scroll before Pin */
            const sh = window.screen.availHeight;
            const offset = (sh - (sh * 0.44) );   
            //console.log("offset = " + offset)
            // EXPLANATION....% of the pinned start position
            // second section starts at 65vh (rest wird gescrolled bis 15vh + 5vh pading -> siehe oben start)
        
            gsap.set(item, {
                left: () => smoothWrapper.left - smoothContent.left,
                top: () => ((smoothWrapper.top - smoothContent.top) - offset ),
            });
            
            item.classList.add("fixed");
            isPinned = true; 
            document.body.append(item);    
            }
    };
    fixedItem();

    window.addEventListener('resize', () => {
        // Re-run the fixedItem function when the window is resized
        fixedItem();
    });
}


    function pinBackHOME() {
        let pinBack = document.getElementById("backHOME");
        const sections = document.querySelectorAll('section');
        gsap.set(pinBack, {yPercent:100});
        gsap.to(pinBack, {  autoAlpha:1, yPercent: 0, ease: "power1.inOut",
        // gsap.set(pinBack, {yPercent: 100});
                scrollTrigger: {
                ease: "power1.inOut",
                trigger: ".second",
                scroller: "#smooth-wrapper",
                id: "pinBack",
                start: ()=> "top 15%",
                endTrigger: ".footer",
                end: "top bottom",
                // end: () => totalHeight - 1200 + adjust,
                invalidateOnRefresh: true,
                //  onUpdate: function(self) {
                //     // Update ScrollTrigger when scrolling within the inner container
                //     self.scroll(self.scroll().x, innerContainer.scrollTop); },
                    //scrub: true,
                    // pin: true,
                    // pinType: "fixed",
                    // pinSpacing: false,
                    // anticipatePin: 1,
                    //markers: true,
                
                }
                })
                
}


    function backLogo() {

    let mainLogo = document.getElementById('mainLogo');
    let logoContInner = document.getElementById("logo-container-inner");
    let triggerLogo = document.querySelector(".second");

    
    gsap.to(logoContInner, {  yPercent: -44, duration: 0.25, transformOrigin: "top left", ease: "power2.inOut",  //was ypercent -65
                         scrollTrigger: {
                        trigger: triggerLogo,
                         scroller: "#smooth-wrapper",
                         id: "mainLogo",
                         start: ()=> "top 25%",
                         end: ()=>  "top 25%",
                         invalidateOnRefresh: true,
                         toggleActions: "play restart reverse none",
                         //onUpdate: function(self) {
                            // Update ScrollTrigger when scrolling within the inner container
                           // self.scroll(self.scroll().x, innerContainer.scrollTop); },
                         //scrub: true,
                         //pin: true,
                         //pinSpacing: false,
                         
                         pinType: "fixed",
                         //markers: true,
                        }
                })


    }


  


    function imageCaptionFadeIn() {
        
       const imageCaptions = document.querySelectorAll(".image-caption-content");
       //console.log("IMAGECAPTIONS" + imageCaptions)

       imageCaptions.forEach(imageCaption => {

        gsap.set(imageCaption, { yPercent: 100 })
        // console.log("IMAGECAPTION" + imageCaption.innerHTML)

        gsap.to(imageCaption, {
            yPercent: 0, duration: 0.5, ease:"power2.inOut",
            scrollTrigger: {
                trigger: imageCaption,
                start: ()=> "top 90%",
                end: ()=>  "bottom 10%",
                toggleActions: "play reverse play reverse",
               
                //markers: true,
            }
        })
       })


    }

    imageCaptionFadeIn();

    //pinInfo();

    // var d = window.matchMedia("(min-width: 1025px)");
    // var tl = window.matchMedia("(min-width:950px) and (max-width: 1024px) and (orientation: landscape)");
    // var tp = window.matchMedia("(min-width: 768px) and (orientation: portrait)");
    // var ml = window.matchMedia("(min-width: 768px) and (max-width: 1023px) and (orientation: landscape) ");
    // var mp = window.matchMedia("(min-width: 320px) and (max-width: 767px) and (orientation: portrait) ");


    // if (d.matches || tl.matches || tp.matches) {

    //     // alert("DESKTOP JS ADJUST");
    //     function imageAniFullTB(elem_tnImage) {
    //         var innerContainer = document.querySelector('.inner-container');
    //         if(elem_tnImage.classList.contains("thumb_move")) {
    //             gsap.set(elem_tnImage, { yPercent: -25 });     
    //             gsap.to(elem_tnImage, {yPercent: 30,ease: "none",
    //                      scrollTrigger: {
    //                      trigger:elem_tnImage,
    //                      scroller: "#smooth-wrapper",
    //                      id: "elem_tnImage2",
    //                      start: ()=> "top bottom",
    //                      end: ()=>  "200%",
    //                      invalidateOnRefresh: true,
    //                      onUpdate: function(self) {
    //                         // Update ScrollTrigger when scrolling within the inner container
    //                         self.scroll(self.scroll().x, innerContainer.scrollTop); },
    //                      scrub: true,
    //                      //pin: true,
    //                      //markers: true,
    //                     }
    //             })
    //         }
    //     };	
    //     function ThumbMove() {
    //        gsap.utils.toArray(".thumb_move").forEach(function(elem_tnImage) {
    //             ScrollTrigger.create({
    //                 trigger: elem_tnImage,
    //                 scroller: "#smooth-wrapper",
    //                 invalidateOnRefresh: true , 
    //                 onEnter: function() { imageAniFullTB(elem_tnImage) }, 
    //             });
    //         });
    //      }
    //      ThumbMove();









    // // } else if (tl.matches) {

    //     // alert("TABLET LANDSCAPE JS ADJUST");


    // // } else if (tp.matches) {

    //     // alert("TABLET PORTRAIT JS ADJUST");

    // // } else if (ml.matches) {

    //     // alert("MOBILE LANDSCAPE JS ADJUST");


    // } else if (mp.matches || tp.matches) {
    //     // alert("MOBILE PORTRAIT JS ADJUST");
    //     function imageAniFullTB(elem_tnImage) {
    //         var innerContainer = document.querySelector('.inner-container');
    //         if(elem_tnImage.classList.contains("thumb_move")) {
    //             gsap.set(elem_tnImage, { yPercent: -10 });     
    //             gsap.to(elem_tnImage, {yPercent: 15,ease: "none",
    //                      scrollTrigger: {
    //                      trigger:elem_tnImage,
    //                      scroller: "#smooth-wrapper",
    //                      id: "elem_tnImage2",
    //                      start: ()=> "top bottom",
    //                      end: ()=>  "200%",
    //                      invalidateOnRefresh: true,
    //                      onUpdate: function(self) {
    //                         // Update ScrollTrigger when scrolling within the inner container
    //                         self.scroll(self.scroll().x, innerContainer.scrollTop); },
    //                      scrub: true,
    //                      //pin: true,
    //                      //markers: true,
    //                     }
    //             })
    //         }
    //     };	
    //     function ThumbMove() {
    //        gsap.utils.toArray(".thumb_move").forEach(function(elem_tnImage) {
    //             ScrollTrigger.create({
    //                 trigger: elem_tnImage,
    //                 scroller: "#smooth-wrapper",
    //                 invalidateOnRefresh: true , 
    //                 onEnter: function() { imageAniFullTB(elem_tnImage) }, 
    //             });
    //         });
    //      }
    //      ThumbMove();




    // }



}