import { gsap, ScrollSmoother } from "gsap/all";

import { smoother } from "./index.js";



async function  allwork() { 

//async function allwork() {

return new Promise((resolve, reject) => {

   // Fetch the projects.json file
    fetch('../json/projects.json')
    .then(response => response.json())
    .then(data => {
        // Get the main container where the menu items will be added
        const mainContainer = document.getElementById('workmenu');

        // Create the background div
        const bg = document.createElement('div');
        bg.className = 'bg';
        mainContainer.appendChild(bg);

        // // Create the close button
        // const closeBtn = document.createElement('div');
        // closeBtn.id = 'closeWorkMenu';
        // closeBtn.innerHTML = '<h4>CLOSE</h4>';
        // // closeBtn.setAttribute('onclick', 'closeWorkMenu()');

        // mainContainer.appendChild(closeBtn);



        // Create the circle menu container
        const circleMenu = document.createElement('div');
        circleMenu.id = 'circleMenu';
        circleMenu.innerHTML = '<div class="bg"></div>';
        // circleMenu.innerHTML = '<div class="bg"></div><div class="text"><h5 data-i18n="workmenu_info"></h5></div>';
        mainContainer.appendChild(circleMenu);

          // Create the logo container
          const logo = document.createElement('div');
          logo.className = 'logo';
          logo.innerHTML = `
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>
                  <defs>
                      <style>
                          .icon {
                              fill: none;
                              stroke: #FFFFFF;
                              stroke-miterlimit: 50;
                          }
                      </style>
                  </defs>
                  <path id='dash1' class='dash' d='M75.42,136.7L132.64,0h-35.56c-10.93,0-13.86,9.44-13.86,9.44L6.21,200h56.32l28.4-77.19-15.51,13.9Z' />
                  <path id='dash2' class='dash' d='M136.68,0l-28.4,77.19,15.51-13.9-57.22,136.7h35.56c10.93,0,13.86-9.44,13.86-9.44L193,0h-56.32Z' />
                  <path id='dash3' class='dash' d='M36.87,200L117.44,0h45.37l-80.57,200h-45.37Z'/>
              </svg>`;
              circleMenu.appendChild(logo);
      


        // Extract all indices from the data keys
            const indices = Object.keys(data)
            .filter(key => key.includes('proj_') && key.includes('_index'))
            .map(key => data[key]);

            // Sort the indices in descending order
            indices.sort((a, b) => b - a);

            // Loop through the sorted indices and create menu items
            indices.forEach((index, sortedIndex) => {
            const projectUrl = data[`proj_${index}_Url`];
            const menuImgUrl = data[`proj_${index}_menuImgUrl`];
            const title = data[`proj_${index}_title`];

            // Create the menu item
            const menuItem = document.createElement('a');
            menuItem.href = projectUrl;
            menuItem.className = 'menu__item js-to-hover';
            menuItem.dataset.imgurl = menuImgUrl;
            menuItem.dataset.index = sortedIndex + 1; // Adjust the index numbering
            menuItem.innerHTML = `
                <div class='menu__item-content'>
                    <span class='menu__item-title'>${title}</span>
                    <span class='menu__item-count'>${sortedIndex + 1}</span>
                </div>`;

            // Append the menu item to the circle menu
            circleMenu.appendChild(menuItem);
            });

            resolve();
        });

        })
        .catch(error => {
            console.error('Error fetching projects:', error);
        });


}

async function allworkCreateMenu() {



            // CIRCLE INDEX MENU
            //WORKING VERSION
        
            const screenHeight = screen.availHeight;
            const menuItems = document.querySelectorAll('.menu__item');
            const numItems = menuItems.length;

          
            function getRadius() {
              // Get the screen height
              const screenHeight = window.innerHeight;
          
              // Check if the device is an iPhone
              if (/iPhone/.test(navigator.userAgent)) {
                  // For iPhones, use a different radius value
                  return screenHeight / 3; // Adjust as needed
              } else {
                  // For other devices, use a default radius value
                  return screenHeight / 1.5; // Adjust as needed
              }
          }
          
          // You can then use this function to get the radius value dynamically
          let radius = getRadius();
          
          //Update the positions based on the updated radius
          function updatePositions() {
              const angleIncrement = (2 * Math.PI) / numItems;
              let angle = 0;
              menuItems.forEach((item, index) => {
                  const x = Math.cos(angle) * radius;
                  const y = Math.sin(angle) * radius;
                  item.style.transform = `translate(${x}px, ${y}px) rotate(${angle}rad)`;
                  angle += angleIncrement;
              });
          }
        




        function handleResize() {
            // Update the radius value on resize
            radius = getRadius();
            
            // Update the positions
            updatePositions();
        }

        window.addEventListener('resize', handleResize);
        updatePositions(); // Call this function initially

              
        /* OBSERVER APPROACH to SCROLL */
        const workmenu = document.getElementById("workmenu");
        const circle = document.getElementById("circleMenu");
        
        var currentRotation = 0;
        var accumulatedScroll = 0;
        var reverseScrollDirection = true; // Variable to control scroll direction inversion
        
        // Function to check if the device is iOS
        function isiOS() {
            return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        }
        let startTouchY;
        
        // Touch event listeners
        window.addEventListener("touchstart", function(event) {
            
                startTouchY = event.touches[0].clientY;
                console.log(startTouchY);
        });
        
        window.addEventListener("touchmove", function(event) {
            var deltaY = event.touches[0].clientY - startTouchY;
            startTouchY = event.touches[0].clientY;
            
            accumulatedScroll += deltaY;
        });

        
        // Mouse wheel event listener
        window.addEventListener("wheel", function(event) {
            var deltaY = event.deltaY;
        
            if (reverseScrollDirection) {
                deltaY *= -1; // Reverse scroll direction if needed
            }
            accumulatedScroll += deltaY;
        });
              
          

        function updateRotation() {
          // Check if the user is on an iOS device
          const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

          // Define scroll speed and duration based on the device type (iOS, iPad, Desktop)
          let scrollSpeed, duration;
          var isIPadPro = /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;

          if (isIOS) {
              // Define scroll speed and duration for iOS devices
              scrollSpeed = 0.4; // Adjust as needed for iOS
              duration = 0.8; // Adjust as needed for iOS
          } else if (isIPadPro) {
              // Define scroll speed and duration for iPad
              scrollSpeed = 0.2; // Adjust as needed for iPad
              duration = 0.4; // Adjust as needed for iPad
          } else {
              // Define scroll speed and duration for Desktop
              scrollSpeed = 0.03; // Adjust as needed for Desktop
              duration = 0.5; // Adjust as needed for Desktop
          }

          // Reverse scroll direction for iOS devices
          //const scrollDirectionMultiplier = (isIOS || isIPadPro) ? -1 : 1;
          const scrollDirectionMultiplier =  -1 

          if (accumulatedScroll !== 0) {
              currentRotation += accumulatedScroll * scrollSpeed * scrollDirectionMultiplier;

              // Apply rotation to each menu item using GSAP
              menuItems.forEach((item, index) => {
                  const perItemRotation = 360 / numItems; // Angle per item
                  const initialRotation = index * perItemRotation; // Initial rotation angle for each item
                  let finalRotation = (initialRotation - currentRotation) % 360; // Calculate final rotation

                  // Adjust final rotation to ensure it stays within the range of 0 to 360 degrees
                  if (finalRotation < 0) {
                      finalRotation += 360;
                  }

                  // Calculate position of the menu item based on the final rotation
                //   const x = Math.cos(finalRotation * (Math.PI / 180)) * radius;
                //   const y = Math.sin(finalRotation * (Math.PI / 180)) * radius;

                  // Calculate position of the menu item based on the final rotation
                const angle = (finalRotation * Math.PI) / 180; // Convert angle to radians
                const x = Math.cos(angle) * radius;
                const y = Math.sin(angle) * radius;

                //const staggerDelay = 0.01; // Adjust as needed

                  // Apply GSAP animation to each menu item
                  gsap.to(item, {
                      x: x,
                      y: y,
                      rotation: `${finalRotation}_short`,
                      duration: duration,
                      ease: "power2.out", // Disable easing for smooth rotation
                     // delay: staggerDelay * index, // Apply stagger delay based on the index of each menu item
                    
                  });
              });

              accumulatedScroll = 0;
          }
          requestAnimationFrame(updateRotation);

        }
       
                



        updateRotation();





                ////  MOUSE POSITION   ////
                
                const getMousePos = (e) => {
                    let posx = 0;
                    let posy = 0;
                if (!e) e = window.event;
                if (e.pageX || e.pageY) {
                        posx = e.pageX;
                  posy = e.pageY;
                }
                else if (e.clientX || e.clientY) 	{
                  posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                  posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
                }
                    return { x : posx, y : posy }
                    //console.log(posx, posy);
                }

                
              //   const getMousePosYInRange = (e) => {
              //     const windowHeight = window.innerHeight;
              //     let positionY = e.clientY;
              //     if (e.pageY || e.clientY) {
              //         positionY = Math.min(Math.max(e.pageY || (e.clientY + document.body.scrollTop + document.documentElement.scrollTop), 0), 1200); // Clamp y position within 0 to 1200 pixels
              //     }
              //     return positionY;
              // };
              
                    const getMousePosYInRange = (e) => {
                        const windowHeight = window.innerHeight;
                        const posY = e.clientY;
                        const posYInRange = Math.min(Math.max(posY, 0), windowHeight);
                        return posYInRange;
                      
                    };

                    const calculateOffset = (mousePosYInRange) => {
                    const screenHeight = window.innerHeight;

                    const offset = -0.5 * screenHeight * (mousePosYInRange / 1200 );
                    return offset;


                };





       

        // Function to preload images for all menu items
        function preloadImages() {
            const menuItems = document.querySelectorAll('.menu__item');
            menuItems.forEach(item => {
                const imgUrl = item.dataset.imgurl;
                if (imgUrl) {
                    const img = new Image();
                    img.src = imgUrl;
                }
            });
        }

        // Function to add hover-reveal divs for all menu items
        function addHoverReveal() {
            const menuItems = document.querySelectorAll('.menu__item');
            menuItems.forEach(item => {
                const imgUrl = item.dataset.imgurl;
                if (imgUrl) {
                    // Create a div with the background image
                    const hoverImgDiv = document.createElement('div');
                    hoverImgDiv.classList.add('hover-img');
                    hoverImgDiv.style.backgroundImage = `url(${imgUrl})`;
                    item.appendChild(hoverImgDiv);
                }
            });
        }

            // Call preloadImages function to preload images when the page loads
            preloadImages();

            // Call addHoverReveal function to add hover-reveal divs for all menu items
            // addHoverReveal();






        class HoverImgReveal {


          constructor(el) {
                this.DOM = { el: el };
                this.menuItem = el;
                this.initEvents();

                this.currentReveal = null; // Track the currently active reveal element
                this.previousReveal = null;

                this.isAnimating = false; // Flag to track animation state // prevent animtion to be triggered of user scrolls tooo fast
                this.animationTimeout = null; // Timeout to reset animation flag
                this.mouseLeaveDuringShowImage = false;
              
                this.animationTimelines = new Map(); // Using a Map to associate timelines with reveal elements
                
            }
          createRevealElement() {
              const reveal = document.createElement('div');
              reveal.className = 'hover-reveal next'; // Initial state is 'next'
              reveal.innerHTML = `<div class="hover-reveal__inner"><div class="hover-reveal__mask"></div><div class="hover-reveal__img" style="background-image:url()"><img src="${this.DOM.el.dataset.imgurl}"></div></div>`;
              return reveal;
          }
       

          showImage() {

          ////CONSTRUCTOR FOR ALIGNMENT OF THE IMAGES
            // this.positionElement = (ev) => {
            //   const mousePosYInRange = getMousePosYInRange(ev);
            //   const offset = calculateOffset(mousePosYInRange);
            //   const mousePos = getMousePos(ev);
            //   const docScrolls = {
            //       left : document.body.scrollLeft + document.documentElement.scrollLeft, 
            //       top : document.body.scrollTop + document.documentElement.scrollTop
            //   };
               
            //  console.log(offset)

            //  const mousePosY = ev.clientY;
            //  const maxOffset = (windowHeight - revealHeight) / 2;
            //     console.log(maxOffset)
            //     // Calculate the offset based on the mouse position relative to the window height
               
            //  const offsetNew = ((mousePosY / windowHeight - 0.5) * maxOffset * 2) - (revealHeight / 4);


            // //   console.log(mousePosYInRange)
            //   this.currentReveal.style.left = `${mousePos.x+20-docScrolls.left}px`;
            // //   this.currentReveal.style.top = `${mousePos.y+offset-docScrolls.top}px`;
            // this.currentReveal.style.top = `${offsetNew}px`;
            // //this.currentReveal.style.top = `${offset}px`;
            // };

            this.positionElement = (ev) => {
                const mousePosYInRange = getMousePosYInRange(ev);
                const offset = calculateOffset(mousePosYInRange);
                const mousePos = getMousePos(ev);
                const docScrolls = {
                    left: document.body.scrollLeft + document.documentElement.scrollLeft,
                    top: document.body.scrollTop + document.documentElement.scrollTop
                };
            
                const windowHeight = window.innerHeight;
                const windowWidth = window.innerWidth;
                const revealHeight = this.currentReveal.offsetHeight; // Height of the element
                const revealWidth = this.currentReveal.offsetWidth; // Height of the element
            
                // Calculate the center of the window accounting for the reveal height
                const windowCenter = windowHeight / 2 - revealHeight / 2;

                // Calculate the maximum offset based on the reveal height
                const maxOffsetY = (windowHeight - revealHeight) / 2;
                const maxOffsetX = (windowWidth - revealWidth) / 2;
                
                // Calculate the offset based on the mouse position relative to the window height
                const mousePosY = ev.clientY;
                const mousePosX = ev.clientX;
                const offsetY = ((mousePosY / windowHeight - 0.5) * maxOffsetY * 2) - (revealHeight / 4);
                //const offsetX = ((mousePosX / windowWidth - 0.5) * maxOffsetX * 2) - (revealWidth / 4);
                const offsetX = (mousePosX + windowWidth / 4 );
               // console.log(offsetX)
            
                // Adjust offset based on the position of the mouse relative to the window center
                const adjustedOffset = offset - windowCenter * (mousePosYInRange / windowHeight);
            
                //this.currentReveal.style.left = `${mousePos.x + 200 - docScrolls.left}px`;
                //this.currentReveal.style.top = `${mousePos.y - docScrolls.top + adjustedOffset}px`;
                this.currentReveal.style.top = `${offsetY}px`;
                this.currentReveal.style.left = `${offsetX}px`;
            };
            



            this.mousemoveFn = ev => requestAnimationFrame(() => {
                  this.positionElement(ev);
            });




            this.DOM.el.addEventListener('mousemove', this.mousemoveFn);


            //SHOW IMAGE FUNC

            const currentReveal = this.createRevealElement();
            this.currentReveal = currentReveal;
            // const imageContainer = document.getElementById("menu__images");
            const imageContainer = document.getElementById("circleMenu");
            imageContainer.appendChild(currentReveal);



        //     // Get the current rotation of #circleMenu
        // const currentRotation = gsap.getProperty('#circleMenu', 'rotation');

        // // Apply reverse rotation to hover-reveal
        // gsap.set('.hover-reveal', { rotation: -currentRotation });





            setTimeout(() => {
                currentReveal.classList.add('active');
                gsap.set(this.DOM.el, { zIndex: 30 });
                this.DOM.el.classList.add("active__item");
                gsap.set(currentReveal, { xPercent: -30, autoAlpha: 1 });
                gsap.set(currentReveal.querySelector('.hover-reveal__mask'), { xPercent: -100, autoAlpha: 0 });
                gsap.set(currentReveal.querySelector('.hover-reveal__inner'), { width: 0, height: 100 + "%", autoAlpha: 1 });
                gsap.set(currentReveal.querySelector('.hover-reveal__img'), { autoAlpha: 0 });
            }, 10); // Add 'active' class with a small delay to trigger the animation

            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            const revealTimelineIn = gsap.timeline({ paused: true,
                  onComplete: () => {
                    // Check if mouseleave was triggered during showImage
                        if (this.mouseLeaveDuringShowImage) {
                            // If yes, trigger hideImage immediately
                            this.hideImage(this.currentReveal);
                        }
                  } });
            gsap.set(this.currentReveal, {zIndex: 20});

            revealTimelineIn
                .to(currentReveal, {
                    xPercent: 10,
                    duration: 0.8,
                    // ease: "power1.in",
                    onStart: () => {
                        this.DOM.el.removeEventListener('mousemove', this.mousemoveFn);
                    },
                    onComplete: () => {
                        revealTimelineIn.reversed();
                        currentReveal.classList.remove('next');
                        currentReveal.classList.add('active');
                    }
                }, 0.1)
                .to(currentReveal.querySelector('.hover-reveal__inner'), {
                    width: "100%",
                    duration: 0.6,
                    // ease: "power1.in",
                }, 0.1)
                .to(currentReveal.querySelector('.hover-reveal__img'), {
                    autoAlpha: 1,
                    duration: 0.5,
                    // ease: "power1.in",
                }, 0.1);

            revealTimelineIn.play();

            // Store the timeline in a Map to check its status later
            //this.animationTimelines.set(currentReveal, revealTimelineIn);


        } // END showIMAGE()
        hideImage2(currentReveal) {console.log("DUMMY HIDE IMAGE FOR TESTING")}
        hideImage(currentReveal) {

          if (!currentReveal) return;

          // const showImageTimeline = this.animationTimelines.get(currentReveal);
          //   if (showImageTimeline && showImageTimeline.isActive()) {
          //       // If showImage animation is still active, delay hideImage until after it completes
          //       showImageTimeline.eventCallback("onComplete", () => {
          //           this.hideImage(currentReveal);
          //       });
          //       return;
          //   }


          // const dataAttributeValue = currentReveal.getAttribute('data-index');
          //     const correspondingMenuItem = document.querySelector(`#circleMenu2 .menu__item2[data-index="${dataAttributeValue}"]`);
          //     gsap.to(correspondingMenuItem, {autoAlpha: 0})


          // HIDE FUNCTION
          this.DOM.el.classList.remove("active__item");
          currentReveal.classList.remove('active');
          currentReveal.classList.add('prev');

          gsap.set(this.DOM.el, {zIndex: 1});
          

          gsap.set(currentReveal, { zIndex: 1 });
          gsap.set('.hover-reveal__mask', { xPercent: -100, autoAlpha: 1 });

          const revealTimelineOut = gsap.timeline({paused: true,
              onComplete: () => {
              
              }
          });

          // Animate hide
          revealTimelineOut
          .to(".hover-reveal__img", {
                  duration: 0.9,
                  autoAlpha: 0,
                  // ease: "power4.in",
                  onComplete: () => {
                    currentReveal.remove();
                    currentReveal = null; // Reset currentReveal after animation completes
                  }
          })
        //   .to(currentReveal.querySelector('.hover-reveal__inner'), {
        //    xPercent: 100,
        //     duration: 20,
        //     ease: "sine.easeOut",
        // }, 0.1)
          // .to(".hover-reveal__mask", {
          //                 duration: 1,
          //                 // ease: "power4.in",
          //                 xPercent: 0,
          // }, 0.2)           
          .to(currentReveal, {
                xPercent: 125,
                duration: 1.2,
                //  ease: "power4.out",
          }, 0.1);


          revealTimelineOut.play();

        
          
          } // END hideImage()



          initEvents() {
            

              // this.DOM.el.addEventListener('mouseenter', () => {
              
              //     this.showImage();
              // });
          
              // this.DOM.el.addEventListener('mouseleave', () => {
              //   this.hideImage(this.currentReveal);
                
              // });



          
              let mouseLeft = true;
              let mouseEnterTime = 0;
              const threshold = 500; // Threshold in milliseconds
          
              this.DOM.el.addEventListener('mouseenter', () => {

                
              
              
                  
                this.showImage();
                
                // mouseEnterTime = new Date().getTime();
                //   if (mouseLeft) {
                //       setTimeout(() => {
                //           const currentTime = new Date().getTime();
                //           const elapsedTime = currentTime - mouseEnterTime;
                //           if (elapsedTime >= threshold) {
                //               this.showImage();
                //           }
                //       }, threshold);
                //   }


              });
          
            this.DOM.el.addEventListener('mouseleave', () => {
              this.DOM.el.style.zIndex = "10";
              this.hideImage(this.currentReveal);
                
              });



              // // Add touchstart event listener
              // this.DOM.el.addEventListener('touchstart', function() {
              //   this.showImage();
              //   // Add hover effect (e.g., change color, scale, etc.)
              //   this.classList.add('hover');
              // });

              // // Add touchend event listener
              // this.DOM.el.addEventListener('touchend', function() {
              //   // Remove hover effect
              //   this.hideImage(this.currentReveal);
              // });
            
            
            
          }

        } ///END OF CLASS HOVERIMGREVEAL

        document.querySelectorAll(".menu__item, a.menu__item").forEach(link => new HoverImgReveal(link));

}



async function workMenuFunc() {
    try {
        console.log('Executing allwork...');
        await allwork();
        // setTimeout(function() {
        console.log('Executing allworkCreateMen...');
        allworkCreateMenu();
    // },5000);
    } catch (error) {
        console.error('An error occurred:', error);
    }
}

module.exports = workMenuFunc;




// async function workMenuFunc() {
//     try {
      
//         console.log('Executing allwork...');
//         await allwork().then(() => {
//             // console.log('allwork executed successfully.');
//             // console.log('Executing allworkCreateMenu...');
//             // setTimeout(function() {
//             //     allworkCreateMenu();
//             //     console.log('allworkCreateMenu executed successfully.');
//             // },200);
//             console.log('Executing allworkCreateMen...');
//             allworkCreateMenu();
           
//         }).catch(error => {
//             console.error('An error occurred in allwork:', error);
//         });
//     } catch (error) {
//         console.error('An error occurred:', error);
//     }

// }

// module.exports = workMenuFunc;



// async function workMenuFunc() {
//         try {
          
//             console.log('Executing allwork...');
//           allwork();
//             allworkCreateMenu();
               
           
//         } catch (error) {
//             console.error('An error occurred:', error);
//         }
    
    
    
    
    
    
    
//     }
    



// module.exports = allwork;
// module.exports = allworkCreateMenu;

